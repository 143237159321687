import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import Input from "../../../../global/atoms/Input";
import ButtonOutlined from "../../../../global/atoms/ButtonOutlined";
import Checkbox from "../../../../global/atoms/Checkbox";
import commonStyles from "../../../../global/common.module.scss";
import { deepClone } from "../../../../utils/helpers";
import { Button, Form, SelectPicker } from "rsuite";
import styles from "./styles.module.scss";
import {
  BOOLEAN_QUESTION,
  DECIMAL_QUESTION,
  DICTIONARY_MULTIPLE_ANSWERS_QUESTION,
  DICTIONARY_QUESTION,
  INTEGER_QUESTION,
  PHOTO_QUESTION,
  TEXT_QUESTION,
  TEXTAREA_QUESTION,
} from "../visits/visitsEdit/VisitElements/QuestionTypeConsts";
import {
  IDictionaryQuestionAnswer,
  IQuestionItem,
} from "../../../../utils/models";

import DependOnQuestionConditionsBuilder from "./DependOnQuestionConditionsBuilder";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import _, { isArray } from "lodash";
import { ValueType } from "rsuite/esm/InputPicker/InputPicker";
import QuestionInput from "./QuestionInput";
import { useSelector } from "react-redux";
import { IRoot } from "../../../../redux/models";

interface IQuestionForm {
  question: IQuestionItem;
  assigned: Array<IQuestionItem>;
  removePicked: () => void;
  setForm: Dispatch<SetStateAction<any>>;
  form: any;
  isReadOnly?: boolean;
  defaultValueEdit?: boolean;
  type?: "default" | "project" | "activity";
  acceptCallback?: () => void;
}

const QuestionForm: FunctionComponent<IQuestionForm> = ({
  question,
  assigned,
  removePicked,
  setForm,
  form,
  defaultValueEdit = false,
  isReadOnly = false,
  type = "default",
  acceptCallback,
}) => {
  const [questionData, setQuestionData] = useState<IQuestionItem>(question);
  const [errors, setErrors] = useState<Array<string>>([]);
  console.log(type);
  const projectSettings = useSelector(
    (root: IRoot) => root.project?.projectSettings
  );

  useEffect(() => {
    setQuestionData(question);
  }, [question]);

  const handleSaveQuestion = () => {
    const tmp = [] as Array<string>;

    if (
      [
        TEXT_QUESTION,
        TEXTAREA_QUESTION,
        INTEGER_QUESTION,
        DECIMAL_QUESTION,
        PHOTO_QUESTION,
      ].includes(question.questionType)
    ) {
      questionData.minValue = parseInt(questionData.minValue.toString());
      questionData.maxValue = parseInt(questionData.maxValue.toString());
      if (questionData.minValue > questionData.maxValue)
        tmp.push(
          "Wartość minimalna nie może być większa od wartości maksymalnej"
        );
    }

    if (tmp.length) {
      setErrors(tmp);
      return;
    }

    const currentQuestion = _.cloneDeep(questionData);
    const aqArr = _.cloneDeep(form.assignedQuestions.data);
    const qid = aqArr.findIndex((q: any) => q.id === question.id);
    aqArr[qid] = {
      ...aqArr[qid],
      ...currentQuestion,
    };

    setForm({
      ...form,
      assignedQuestions: {
        count: form.assignedQuestions.count,
        data: aqArr,
      },
    });

    removePicked();
    if (acceptCallback) acceptCallback();
  };

  const getDependQuestionObject = (): null | IQuestionItem => {
    return (
      assigned.find((q) => q.id === questionData.dependOnQuestion.id) ?? null
    );
  };

  const handleChangeDependQuestionAnswers = (answerId: string) => {
    const dependOnQuestionClone = deepClone(questionData.dependOnQuestion);
    const answersFound = dependOnQuestionClone.options.filter(
      //@ts-ignore
      (opt: IDictionaryQuestionAnswer) => opt.id === answerId
    );

    if (answersFound.length > 0) {
      dependOnQuestionClone.options = dependOnQuestionClone.options.filter(
        //@ts-ignore
        (opt: IDictionaryQuestionAnswer) => opt.id !== answerId
      );
    } else dependOnQuestionClone.options.push({ id: answerId });

    setQuestionData({
      ...questionData,
      dependOnQuestion: dependOnQuestionClone,
    });
  };

  const dependAnswerIsChecked = (answerId: string) => {
    return (
      (questionData.dependOnQuestion.options.find(
        (opt) => opt.id === answerId
      ) ?? null) !== null
    );
  };

  const handleChangeDependOnQuestion = (questionId: ValueType) => {
    const dependQuestion = assigned.find((a) => a.id === questionId);
    const tmp = deepClone(question.dependOnQuestion);
    tmp.id = questionId;
    tmp.questionName = dependQuestion?.name ?? "";
    tmp.questionType = dependQuestion?.questionType ?? "";
    tmp.options = [];
    tmp.valueConditions = [];

    if (questionId !== "") {
      // is not empty
      const dependOn = assigned.find((qi) => qi.id === questionId);
      if (!dependOn) throw "depend on question item not found!";
      if (
        tmp.options.length <= 0 &&
        dependOn &&
        dependOn?.options?.length > 0
      ) {
        tmp.options = dependOn.options.map((opt: { id: string }) => ({
          id: opt.id,
        }));
        // tmp.dependOnQuestion.refQuestionId = dependOn.refQuestionId;
        // tmp.dependOnQuestion.sourceId = dependOn.sourceId;
      }
    }

    setQuestionData({ ...questionData, dependOnQuestion: tmp });
  };

  const handleChangeDefaultBoolValue = (val: ValueType) => {
    setQuestionData({
      ...questionData,
      defaultBoolValue: val === "" ? null : val === "1",
    });
  };

  const Q_NUMBER_MAX = "input_number_max";
  const Q_NUMBER_MIN = "input_number_min";
  const Q_TEXT_MAX = "input_text_max";
  const Q_TEXT_MIN = "input_text_min";
  const Q_CHECKBOX = "input_checkbox";
  const Q_DEFAULT_VALUE = "input_default_value";
  const Q_DICTIONARY = "input_dictionary";

  const questionTypeToFormType = (formType: string): boolean => {
    const qt = question.questionType.toUpperCase();

    switch (formType) {
      case Q_DEFAULT_VALUE:
        return qt === BOOLEAN_QUESTION;
      case Q_CHECKBOX:
        return [BOOLEAN_QUESTION, INTEGER_QUESTION].includes(qt);
      case Q_NUMBER_MIN:
      case Q_TEXT_MIN:
        return [
          PHOTO_QUESTION,
          INTEGER_QUESTION,
          DECIMAL_QUESTION,
          DICTIONARY_MULTIPLE_ANSWERS_QUESTION,
          TEXT_QUESTION,
          TEXTAREA_QUESTION,
        ].includes(qt);
      case Q_NUMBER_MAX:
      case Q_TEXT_MAX:
        return [
          PHOTO_QUESTION,
          INTEGER_QUESTION,
          DECIMAL_QUESTION,
          DICTIONARY_MULTIPLE_ANSWERS_QUESTION,
          TEXT_QUESTION,
          TEXTAREA_QUESTION,
        ].includes(qt);
      case Q_DICTIONARY:
        return [
          DICTIONARY_QUESTION,
          DICTIONARY_MULTIPLE_ANSWERS_QUESTION,
        ].includes(qt);
    }

    return false;
  };

  const getDependentQuestionOptions = (): Array<{
    id: string;
    name: string;
  }> => {
    return [
      { id: "", name: "Brak nadrzędnego pytania" },
      ...assigned
        .filter((item) => {
          if (item.id === question.id) return false;
          return item.dependOnQuestion.id !== question.id;
        })
        .map((item) => ({
          id: item.id,
          name: item.questionName,
        })),
    ];
  };

  return (
    <div className={styles.questionWrapper}>
      <div className={styles.questionWrapperInner}>
        <div className={styles.flexColumn}>
          <Checkbox
            disabled={isReadOnly}
            isChecked={questionData.isRequired}
            isMixed={false}
            onChange={(value, checked) => {
              setQuestionData((state: any) => ({
                ...state,
                isRequired: checked,
                isEdit: checked ? true : state.isEdit,
              }));
            }}>
            Pytanie wymagane
          </Checkbox>
          <Checkbox
            disabled={isReadOnly}
            isChecked={questionData.isEdit}
            isMixed={false}
            onChange={(value, checked) => {
              if (questionData.isEdit && questionData.isRequired) {
                alert("Pytanie wymagane musi pozostać możliwe do edycji");
                return;
              }
              setQuestionData((state: any) => ({
                ...state,
                isEdit: checked,
              }));
            }}>
            Możliwość edycji
          </Checkbox>

          {questionTypeToFormType(Q_CHECKBOX) && (
            <Checkbox
              disabled={isReadOnly}
              isChecked={questionData.isEnableForFeature}
              isMixed={false}
              onChange={(value, checked) =>
                setQuestionData((state: any) => ({
                  ...state,
                  isEnableForFeature: checked,
                }))
              }>
              Generuj pozycję zamówienia
            </Checkbox>
          )}
        </div>

        {type !== "project" && (
          <>
            <div className={styles.flexBetween}>
              {(questionTypeToFormType(Q_NUMBER_MIN) ||
                questionTypeToFormType(Q_TEXT_MIN)) && (
                <Input
                  disabled={isReadOnly}
                  type={"number"}
                  placeholder={"Min"}
                  name={"minValue"}
                  value={questionData.minValue}
                  state={questionData}
                  setState={setQuestionData}
                />
              )}

              {(questionTypeToFormType(Q_NUMBER_MAX) ||
                questionTypeToFormType(Q_TEXT_MAX)) && (
                <Input
                  disabled={isReadOnly}
                  type={"number"}
                  placeholder={"Max"}
                  name={"maxValue"}
                  value={questionData.maxValue}
                  state={questionData}
                  setState={setQuestionData}
                />
              )}
            </div>

            {questionTypeToFormType(Q_DEFAULT_VALUE) && (
              <div style={{ width: "100%" }}>
                <p style={{ marginBottom: "4px" }}>Domyślna wartość</p>
                <SelectPicker
                  disabled={isReadOnly}
                  block
                  cleanable={false}
                  searchable={false}
                  placeholder={"Wybierz pytanie"}
                  name={"defaultBoolValue"}
                  value={
                    questionData.defaultBoolValue === null
                      ? ""
                      : questionData.defaultBoolValue
                      ? "1"
                      : "0"
                  }
                  data={[
                    { value: "", label: "Brak" },
                    { value: "0", label: "NIE" },
                    { value: "1", label: "TAK" },
                  ]}
                  onChange={handleChangeDefaultBoolValue}
                />
              </div>
            )}

            <>
              {/*<SeparatorLine size={0} />*/}
              <div style={{ width: "100%" }}>
                <p style={{ marginBottom: "4px" }}>
                  Pokazuj zależnie od odpowiedzi na pytanie
                </p>
                <SelectPicker
                  disabled={isReadOnly}
                  block
                  cleanable={false}
                  placeholder={"Wybierz pytanie"}
                  name={"dependQuestionId"}
                  // disabled={questionData.isRequired}
                  value={questionData.dependOnQuestion.id}
                  data={getDependentQuestionOptions().map((o) => ({
                    label: o.name,
                    value: o.id,
                  }))}
                  onChange={handleChangeDependOnQuestion}
                />
              </div>

              {/*{ questionData.isRequired &&*/}
              {/*  <div style={{position: 'relative', top: '-15px'}}>*/}
              {/*      <small>Pytanie wymagane nie może być jednocześnie zależne od innego pytania</small>*/}
              {/*  </div>*/}
              {/*}*/}

              {1 && (
                <>
                  {/* DICTIONARY TYPE QUESTION */}
                  {[
                    DICTIONARY_QUESTION,
                    DICTIONARY_MULTIPLE_ANSWERS_QUESTION,
                  ].includes(getDependQuestionObject()?.questionType ?? "") && (
                    <section style={{ display: "block", width: "100%" }}>
                      <div>
                        {getDependQuestionObject()?.options.map(
                          (data: IDictionaryQuestionAnswer, index: number) => (
                            <div
                              key={`depended-question-${index}`}
                              className={commonStyles.dragAndDropElement}>
                              <Checkbox
                                disabled={isReadOnly}
                                isMixed={false}
                                key={index}
                                isChecked={dependAnswerIsChecked(data.id)}
                                onClick={handleChangeDependQuestionAnswers.bind(
                                  null,
                                  data.id
                                )}>
                                {data.name}
                              </Checkbox>
                            </div>
                          )
                        )}
                      </div>
                    </section>
                  )}

                  {/* NUMERIC TYPE QUESTION */}
                  {[
                    INTEGER_QUESTION,
                    DECIMAL_QUESTION,
                    BOOLEAN_QUESTION,
                  ].includes(getDependQuestionObject()?.questionType ?? "") && (
                    <DependOnQuestionConditionsBuilder
                      // @ts-ignore
                      questionType={getDependQuestionObject()?.questionType}
                      valueConditions={
                        questionData.dependOnQuestion.valueConditions
                      }
                      onChange={(conditions) => {
                        setQuestionData({
                          ...questionData,
                          dependOnQuestion: {
                            ...questionData.dependOnQuestion,
                            // sourceId: getDependQuestionObject()?.sourceId ?? '',
                            // refQuestionId: getDependQuestionObject()?.refQuestionId ?? '',
                            valueConditions: conditions,
                          },
                        });
                      }}
                    />
                  )}
                </>
              )}
            </>
            {questionData.questionType !== PHOTO_QUESTION &&
              type !== "activity" && (
                <>
                  <p>
                    Wartość predefiniowana
                    <small> (Zapisana w momencie generowania wizyty)</small>
                  </p>
                  <div style={{ width: "100%" }}>
                    <Form
                      fluid
                      onChange={(value: { [refQuestionId: string]: any }) => {
                        let tmp = value[questionData.refQuestionId];
                        if (isArray(tmp)) {
                          tmp = tmp.map((v) => ({
                            name: questionData.questionName,
                            value: v,
                            refQuestionAnswerId: "",
                          }));
                        } else {
                          tmp = [
                            {
                              name: questionData.questionName,
                              value: tmp,
                              refQuestionAnswerId: "",
                            },
                          ];
                        }

                        setQuestionData((q) => ({
                          ...q,
                          values: tmp,
                        }));
                      }}>
                      <QuestionInput
                        question={questionData}
                        isDisabled={
                          !!projectSettings?.isEnableHistoryQuestionAnswers ||
                          (!defaultValueEdit && !questionData.isEdit)
                        }
                      />
                    </Form>
                  </div>
                </>
              )}

            {errors.map((item, idx: number) => (
              <span className={styles.errors} key={idx}>
                {item}
              </span>
            ))}
          </>
        )}

        {form.ReportKeys && form.ReportKeys.length > 0 && (
          <div style={{ width: "100%" }}>
            <SeparatorEmpty size={0.5} />
            <label>Kolumna w raportach</label>
            <br />
            <SelectPicker
              data={form.ReportKeys}
              placeholder={"Wybierz kolumnę w raportach"}
              style={{ width: "100%" }}
              value={questionData.reportKey}
              onChange={(reportKey) => {
                setQuestionData({
                  ...questionData,
                  // @ts-ignore
                  reportKey: reportKey ?? "",
                });
              }}
            />
            <SeparatorEmpty size={0.5} />
          </div>
        )}
      </div>

      <SeparatorEmpty size={1.5} />

      <ActionsContainer>
        {[INTEGER_QUESTION, DECIMAL_QUESTION, BOOLEAN_QUESTION].includes(
          getDependQuestionObject()?.questionType ?? ""
        ) && (
          <Button
            appearance={"ghost"}
            onClick={() => {
              setQuestionData({
                ...questionData,
                dependOnQuestion: {
                  ...questionData.dependOnQuestion,
                  valueConditions: [],
                },
              });
            }}>
            Zresetuj formularz
          </Button>
        )}
        <ButtonOutlined onClick={removePicked}>Anuluj</ButtonOutlined>
        <Button appearance={"primary"} onClick={handleSaveQuestion}>
          Akceptuj
        </Button>
      </ActionsContainer>
    </div>
  );
};

export default QuestionForm;
