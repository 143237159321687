import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import Button from "rsuite/Button";
import SeparatorLine from "../atoms/separators/SeparatorLine";
import { Checkbox, Drawer, Form } from "rsuite";
import { localDatePickerOutputType } from "../atoms/LocalDatePicker";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import IconSvg from "../atoms/IconHelper";
import { flatten, unflatten } from "flat";
import FilterSelectedHeader from "./FilterSelectedHeader";
import _, { isArray, isEmpty } from "lodash";
import {
  FormGroupDatePicker,
  FormGroupMultiSelect,
  FormGroupSelect,
  FormGroupText,
  FormRequired,
} from "utils/formHelper";
import dayjs from "dayjs";
import ToastNotificationPush, { ToastTypes } from "global/ToastNotification";
import { SetState } from "utils/models";
import { NotCancelErrorPromise, deepClone } from "utils/helpers";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";

export const FILTER_INPUT_TEXT = "input_text";
export const FILTER_INPUT_NUMBER = "input_number";
export const FILTER_INPUT_DATE = "input_date";
export const FILTER_SELECT = "select";
export const FILTER_MULTI_SELECT = "multi_select";
export const FILTER_SEPARATOR = "separator";
export const FILTER_INPUT_CHECKBOX = "checkbox";

export type ComponentType =
  | "input_text"
  | "input_number"
  | "input_date"
  | "select"
  | "multi_select"
  | "date_range"
  | "user_role_select"
  | "separator"
  | "network_location_select"
  | "checkbox";
export interface IUniversalFilterElement {
  type: ComponentType;
  label: string;
  stateKey: string | Array<string>;
  stateActiveKey?: string;
  stateActiveValue?: string;
  formKey?: string | Array<string>;
  nameKey?: string | Array<string>;
  defaultValueFromKey?: string;
  setFirstFormValue?: boolean; // set first value (option id) from filter form (default = false)
  headerLabelDisabled?: boolean;
  outputFormat?: localDatePickerOutputType;
  filter?: string | Array<string>;
  required?: boolean;
  groupBy?: string;
  filterIgnoreIfEmpty?: boolean;
}

interface IUniversalFilters {
  load: (state: any) => Promise<AxiosResponse<any>>;
  formGet?: () => Promise<AxiosResponse<any>>;
  setLoading: SetState<boolean>;
  state: any;
  setState: SetState<any>;
  defaultStateData: any;
  setResultData: SetState<any>;
  filterStorageKey: string;
  headerLabelsDisabled?: boolean;
  triggerLoad?: number; // timestamp state - external trigger on action
  getFormCallback?: (formData: any) => void;
  elements: Array<IUniversalFilterElement>;
  toggleOnInit?: boolean; // show sidebar on init while filter is not empty (default = true)
  ignoredStateKeys?: Array<string>; // those keys don't influence active/unactive state
  hiddenFilters?: any;
  filterButtonHidden?: boolean;
}

const BTN_LABEL = "Filtry";

export const GetString = (
  key: string | Array<string> | undefined,
  index = 0
): string => {
  if (key === undefined) return "";
  if (typeof key !== "string") return key[index];
  return key;
};

type TypeInnerTriggerSource =
  | "init"
  | "local-storage"
  | "external"
  | "pagination-sort-limit"
  | "new-state"
  | "hidden-filters";

// todo: do przeniesienia części stałe, ShipButtonThruPortal out
const UniversalFilters: FunctionComponent<IUniversalFilters> = (props) => {
  const storageKey = props.filterStorageKey ?? "globalFilter";
  const [form, setForm] = useState<any | null>(null);
  const [formBlockingError, setFormBlockingError] = useState<boolean>(false);
  const HeaderButtonsElm = document.getElementById("filter-button");
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [storageState, setStorageState] = useState(); // holds only initial value - updated at component mount
  const dispatch = useDispatch();
  const initLoaded = useRef<boolean>(false);
  const formLoaded = useRef<boolean>(false);
  const [submittedState, setSubmittedState] = useState(props.state);
  const [innerTriggerLoad, setInnerTriggerLoad] = useState<{
    time: number;
    source: null | TypeInnerTriggerSource;
  }>({ time: 0, source: null });
  const flattenSettings = { safe: true };
  const unflattenSettings = {};

  // ######## useEffects ########

  // init
  useEffect(() => {
    const _storageState = JSON.parse(localStorage.getItem(storageKey) ?? "{}");
    setStorageState(_storageState);

    if (!_.isEmpty(_storageState)) {
      props.setState(_storageState);
      setInnerTriggerLoad({ time: Date.now(), source: "local-storage" });
    } else setInnerTriggerLoad({ time: Date.now(), source: "init" });
    if (props.formGet) loadFrom();
    else setForm(deepClone(props.defaultStateData));

    return () => {
      setInnerTriggerLoad({ time: 0, source: null });
      setForm(null);
      setStorageState(undefined);
    };
  }, []);

  // external triggerss
  useEffect(() => {
    if (props.triggerLoad !== undefined && props.triggerLoad !== 0) {
      setInnerTriggerLoad({ time: Date.now(), source: "external" });
    }
  }, [props.triggerLoad]);

  // pagination
  useEffect(() => {
    if (initLoaded.current && !isFilterChanged()) {
      // if filter changed skip - it will be triggered elsewhere - avoid duplicated trigger
      setInnerTriggerLoad({
        time: Date.now(),
        source: "pagination-sort-limit",
      });
    }
  }, [props.state.requestPaginate, props.state.requestOrder]);

  // grand inner load trigger
  useEffect(() => {
    if (innerTriggerLoad.time === 0) return;
    load(props.state);
  }, [innerTriggerLoad.time]);

  // ######## /useEffects ########

  const isFilterMissingRequired = (state: any): boolean => {
    let result = false;

    Object.entries(flatten(state, flattenSettings) as Array<string>).every(
      ([key, value]) => {
        props.elements.every((elm) => {
          // @ts-ignore
          if (elm.required && key === GetString(elm.stateKey) && !value) {
            result = true;
          }
          return !result;
        });
        return !result;
      }
    );
    return result;
  };

  // compare submitedState with current state, excluding pagination and sorting fields and ignoredstatekeys
  const isFilterChanged = (): boolean => {
    const compareState = deepClone(submittedState);
    const cloneState = deepClone(props.state);
    cloneState.requestPaginate = null;
    cloneState.requestOrder = null;

    compareState.requestPaginate = null;
    compareState.requestOrder = null;

    if (props.ignoredStateKeys) {
      props.ignoredStateKeys.forEach((key) => {
        cloneState[key] = null;
        compareState[key] = null;
      });
    }

    return !_.isEqual(cloneState, compareState);
  };

  const isFilterEmpty = (compareState?: any): boolean => {
    const cloneState = deepClone(compareState ?? props.state);
    cloneState.requestPaginate = null;
    cloneState.requestOrder = null;

    const cloneDefaultState = deepClone(props.defaultStateData);
    cloneDefaultState.requestPaginate = null;
    cloneDefaultState.requestOrder = null;

    if (props.ignoredStateKeys) {
      props.ignoredStateKeys.forEach((key) => {
        cloneState[key] = null;
        cloneDefaultState[key] = null;
      });
    }
    return _.isEqual(cloneState, cloneDefaultState);
  };

  const proxySubmitNewState = (state: any) => {
    props.setState(state);
    setInnerTriggerLoad({ time: Date.now(), source: "new-state" });
  };

  const proxyStoreFilter = (state: any) => {
    const stateCloned = deepClone(state);

    if (props.ignoredStateKeys) {
      props.ignoredStateKeys.forEach((key) => {
        delete stateCloned[key];
      });
    }

    localStorage.setItem(storageKey, JSON.stringify(state));
  };

  const load = (state: any) => {
    proxyStoreFilter(state);
    props.setLoading(true);
    setSubmittedState(unflatten(state, unflattenSettings));

    let filters = state;
    if (props.hiddenFilters !== undefined) {
      filters = { ...state, ...props.hiddenFilters };
    }
    props
      .load(filters)
      .then((res: any) => {
        if (res?.data.paginateResult)
          props.setResultData(res.data.paginateResult.data);
        else props.setResultData(res?.data);
        res?.data?.header &&
          dispatch({ type: "SET_HEADER", payload: res.data.header });
        props.setLoading(false);
        initLoaded.current = true;
      }, NotCancelErrorPromise)
      .catch((err) => {
        ToastNotificationPush(
          ToastTypes.error,
          "Wystąpił błąd podczas pobierania danych!",
          err?.response?.data?.message ?? ""
        );
      });
  };

  const loadFrom = () => {
    if (!props.formGet) return;
    // todo: refactor
    props
      .formGet()
      .then((res) => {
        setForm(res.data);

        const flatFromData: Array<any> = flatten(res.data, flattenSettings);
        const flatState: Array<any> = flatten(
          storageState ? storageState : props.state,
          flattenSettings
        );
        let hasDefaultValues = false;

        // apply default values
        props.elements.forEach((elm) => {
          if (elm.defaultValueFromKey) {
            // @ts-ignore
            if (
              flatFromData[elm.defaultValueFromKey] &&
              !flatState[GetString(elm.stateKey)]
            ) {
              // @ts-ignore
              flatState[GetString(elm.stateKey)] =
                flatFromData[elm.defaultValueFromKey];
              hasDefaultValues = true;
            }
          } else if (elm.setFirstFormValue) {
            if (res.data[GetString(elm.formKey)]?.options[0]?.id) {
              // @ts-ignore
              flatState[GetString(elm.stateKey)] =
                res.data[GetString(elm.formKey)]?.options[0]?.id;
              hasDefaultValues = true;
            }
          }
        });

        if (hasDefaultValues) {
          props.setState(unflatten(flatState, unflattenSettings));
          setSubmittedState(unflatten(flatState, unflattenSettings));
        }

        if (props.getFormCallback) props.getFormCallback(res.data);
        formLoaded.current = true;

        if (isFilterMissingRequired(props.state)) {
          setToggleFilters(true); // open filter drawer
        }
      })
      .catch(() => {
        setFormBlockingError(true);
      });
  };

  if (props.hiddenFilters !== undefined) {
    useEffect(() => {
      if (initLoaded.current) {
        setInnerTriggerLoad({ time: Date.now(), source: "hidden-filters" });
      }
    }, [...Object.values(props.hiddenFilters)]);
  }

  const ShipButtonThruPortal = (btn: JSX.Element) => {
    return (
      <>{HeaderButtonsElm && ReactDOM.createPortal(btn, HeaderButtonsElm)}</>
    );
  };

  // blocking error
  if (formBlockingError)
    return (
      <>
        {ShipButtonThruPortal(
          <Button
            appearance={"ghost"}
            onClick={() => window.location.reload()}
            style={{ borderColor: "red" }}>
            {IconSvg(faTimes, undefined, false, "red")}
          </Button>
        )}
      </>
    );

  // loading
  if (form === null)
    return (
      <>
        {ShipButtonThruPortal(
          <Button
            appearance={"ghost"}
            className={styles.buttonOutlinedLoading}
            disabled={true}>
            {BTN_LABEL}
          </Button>
        )}
      </>
    );

  if (!HeaderButtonsElm) {
    throw "Header-button not found in DOM, you need this element to inject filter button.";
  }

  const handleSubmitFilters = (reset?: boolean) => {
    let stateCloned = _.cloneDeep(props.state);
    const _stateDefault: Array<string> = flatten(
      props.defaultStateData,
      flattenSettings
    );

    if (reset) {
      const flatState: Array<string> = flatten(props.state, flattenSettings);

      if (props.ignoredStateKeys) {
        props.ignoredStateKeys.forEach((key) => {
          _stateDefault[key] = flatState[key];
        });
      }

      // skip required fields
      Object.keys(_stateDefault).forEach((stateKey) => {
        if (props.elements.find((e) => e.stateKey === stateKey)?.required) {
          _stateDefault[stateKey] = flatState[stateKey];
        }
      });

      stateCloned = unflatten(_stateDefault, unflattenSettings);
    } else {
      // date parse to desired format
      const date_inputs = props.elements.filter(
        (e) => e.type === FILTER_INPUT_DATE
      );
      const flatten_state: Array<string> = flatten(
        stateCloned,
        flattenSettings
      );
      date_inputs.forEach((di) => {
        if (flatten_state[GetString(di.stateKey)]) {
          let dateValue: any;
          const dayjsVal = dayjs(flatten_state[GetString(di.stateKey)]);

          switch (di.outputFormat) {
            case "Dayjs":
              dateValue = dayjsVal;
              break;
            case "Date":
              dateValue = dayjsVal.toDate();
              break;
            case "jsTimestamp":
              dateValue = dayjsVal.valueOf();
              break;
            case "phpTimestamp":
              dateValue = dayjsVal.unix();
              break;
            case "yyyy-MM-dd":
              dateValue = dayjsVal.format("YYYY-MM-DD");
              break;
            default:
              dateValue = dayjsVal.unix();
              break;
          }

          flatten_state[GetString(di.stateKey)] = dateValue;
        }
      });

      // for null values (rsuite field clean) reassign default value, which sometimes is not null while it should be todo: ie.: date = 0 as "empty" value
      props.elements.forEach((elm) => {
        if (flatten_state[GetString(elm.stateKey)] === null) {
          flatten_state[GetString(elm.stateKey)] =
            _stateDefault[GetString(elm.stateKey)];
        }
      });

      stateCloned = unflatten(flatten_state, unflattenSettings);
    }

    setToggleFilters(false);
    stateCloned.requestPaginate = {
      page: 1,
      limit: stateCloned.requestPaginate.limit,
    };

    proxySubmitNewState(stateCloned);
  };

  const formParsedState = (_state: any) => {
    // date parse to desired format
    const date_inputs = props.elements.filter(
      (e) => e.type === FILTER_INPUT_DATE
    );
    const flatten_state: Array<any> = flatten(_state, flattenSettings);
    date_inputs.forEach((di) => {
      const date = flatten_state[GetString(di.stateKey)];
      // todo: backend refactor - for now support for date in unix timestamp, and 0 as no value...
      if (date === 0 || date === "")
        flatten_state[GetString(di.stateKey)] = undefined;
      else if (date) {
        if (typeof date === "number") {
          flatten_state[GetString(di.stateKey)] = dayjs.unix(date).toDate();
        } else flatten_state[GetString(di.stateKey)] = dayjs(date).toDate();
      }
    });

    return flatten_state;
  };

  const clearDependentStates = (elm: IUniversalFilterElement) => {
    const dependedElm = props.elements.find((e) => {
      if (!e.filter) return false;
      if (typeof e.filter === "string")
        return GetString(elm.stateKey) === e.filter;
      // @ts-ignore
      return GetString(elm.stateKey) === e.filter[0]; // 0 - statekey, 1 - formkey
    });

    if (dependedElm) {
      props.setState((s: any) => ({
        ...s,
        [GetString(dependedElm.stateKey)]: null,
      }));
      clearDependentStates(dependedElm);
    }
  };

  return (
    <>
      {props.elements.length > 0 &&
        props.filterButtonHidden !== true &&
        ShipButtonThruPortal(
          <Button
            key={`filter_btn`}
            size={"sm"}
            onClick={() => setToggleFilters((state: boolean) => !state)}
            appearance={isFilterEmpty() ? "ghost" : "primary"}>
            {BTN_LABEL}
          </Button>
        )}
      {!props.headerLabelsDisabled && (
        <FilterSelectedHeader
          elements={props.elements}
          state={submittedState}
          form={form}
          onRemoved={(elm: IUniversalFilterElement) => {
            const _state: Array<string> = flatten(
              _.cloneDeep(props.state),
              flattenSettings
            );
            const _stateDefault: Array<string> = flatten(
              props.defaultStateData,
              flattenSettings
            );
            _state[GetString(elm.stateKey)] =
              _stateDefault[GetString(elm.stateKey)] ?? null;
            proxySubmitNewState(unflatten(_state, unflattenSettings));
          }}
        />
      )}

      <Drawer
        size={"xs"}
        placement={"right"}
        key={`filter_sidebar`}
        open={toggleFilters}
        onClose={() => setToggleFilters(false)}>
        <Drawer.Header>
          <Drawer.Title>{BTN_LABEL}</Drawer.Title>
          <Drawer.Actions>
            <Button
              appearance={"ghost"}
              onClick={() => handleSubmitFilters(true)}>
              Wyczyść
            </Button>
            <Button
              onClick={() => handleSubmitFilters()}
              disabled={isFilterMissingRequired(props.state)}
              appearance={"primary"}>
              Szukaj
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body style={{ padding: "30px" }}>
          <FormRequired
            requiredFields={{
              // todo: refactor
              text:
                props.elements
                  .filter((e) => e.type === FILTER_INPUT_TEXT && e.required)
                  ?.map((e) => GetString(e.stateKey)) ?? undefined,
              date:
                props.elements
                  .filter((e) => e.type === FILTER_INPUT_DATE && e.required)
                  ?.map((e) => GetString(e.stateKey)) ?? undefined,
              select:
                props.elements
                  .filter(
                    (e) =>
                      [FILTER_SELECT, FILTER_MULTI_SELECT].indexOf(e.type) >=
                        0 && e.required
                  )
                  ?.map((e) => GetString(e.stateKey)) ?? undefined,
            }}
            onSubmit={() => handleSubmitFilters()}
            onChange={(state) => {
              const unflattenState = unflatten(state, unflattenSettings);
              // @ts-ignore
              props.setState((s) => ({ ...s, ...unflattenState }));
            }}
            state={formParsedState(props.state)}>
            {props.elements.map((elm, index) => {
              let resultElm = <></>;
              switch (elm.type) {
                case FILTER_INPUT_CHECKBOX: {
                  resultElm = (
                    <Form.Group>
                      <Form.Control
                        name={GetString(elm.stateKey)}
                        style={{ width: "100%" }}
                        disabled={
                          form ? form[GetString(elm.formKey)]?.disabled : false
                        }
                        accepter={Checkbox}
                        onChange={(value, checked) => {
                          props.setState((s) => ({
                            ...s,
                            [elm.stateKey as string]: checked,
                          }));
                        }}
                        checked={props.state[GetString(elm.stateKey)]}>
                        {elm.label}
                      </Form.Control>
                    </Form.Group>
                  );
                  break;
                }
                case FILTER_INPUT_TEXT:
                case FILTER_INPUT_NUMBER: {
                  resultElm = (
                    <FormGroupText
                      fieldName={GetString(elm.stateKey)}
                      label={elm.label}
                      placeholder={""}
                      disabled={
                        form ? form[GetString(elm.formKey)]?.disabled : false
                      }
                    />
                  );
                  break;
                }
                case FILTER_INPUT_DATE: {
                  resultElm = (
                    <FormGroupDatePicker
                      helperText={elm.required ? "Data wymagana" : undefined}
                      cleanable={!elm.required}
                      fieldName={GetString(elm.stateKey)}
                      label={elm.label}
                    />
                  );
                  break;
                }
                case FILTER_MULTI_SELECT:
                case FILTER_SELECT: {
                  const fieldName = GetString(elm.stateKey);

                  let options = form
                    ? form[GetString(elm.formKey)]?.options ?? []
                    : [];
                  let disabled = form
                    ? form[GetString(elm.formKey)]?.disabled
                    : false;

                  try {
                    const filterStateKey =
                      typeof elm.filter === "object"
                        ? elm.filter[0]
                        : elm.filter ?? "";
                    const filterOptionKey: string =
                      typeof elm.filter === "object"
                        ? elm.filter[1]
                        : elm.filter ?? "";
                    const isEmptyFilter = isEmpty(props.state[filterStateKey]);
                    const skippFiltering =
                      elm.filterIgnoreIfEmpty && isEmptyFilter;
                    if (elm.filter && !isEmptyFilter) {
                      options = options.filter((o) => {
                        if (skippFiltering) return true;
                        if (isArray(props.state[filterStateKey])) {
                          // @ts-ignore
                          return props.state[filterStateKey].includes(
                            o[filterOptionKey]
                          );
                        } else
                          return (
                            o[filterOptionKey] === props.state[filterStateKey]
                          );
                      });
                      if (options.length === 0) disabled = true;
                    } else if (elm.filter && isEmptyFilter && !skippFiltering) {
                      disabled = true;
                    }
                  } catch (e) {
                    console.warn("FILTER_SELECT filter failure", e);
                  }

                  if (elm.nameKey && options.length) {
                    try {
                      options = options.map((o: Array<string>) => {
                        const nameArr: Array<string> = [];
                        if (isArray(elm.nameKey)) {
                          elm.nameKey.forEach((nk) => {
                            // @ts-ignore
                            nameArr.push(o[nk]);
                          });
                        } else {
                          // @ts-ignore
                          nameArr.push(o[elm.nameKey]);
                        }
                        return { ...o, name: nameArr.join(" ") };
                      });
                    } catch (e) {
                      console.warn(
                        "FILTER_SELECT filter nameKey mapper failure",
                        e
                      );
                    }
                  }

                  const Element =
                    elm.type === FILTER_SELECT
                      ? FormGroupSelect
                      : FormGroupMultiSelect;

                  resultElm = (
                    <Element
                      groupBy={elm.groupBy}
                      helperText={elm.required ? "Pole wymagane" : undefined}
                      cleanable={!elm.required}
                      fieldName={fieldName}
                      renderMenuItem={(label, el) => {
                        if (
                          elm.stateActiveKey &&
                          el[elm.stateActiveKey] !== elm?.stateActiveValue
                        ) {
                          return (
                            <span style={{ color: "#13151552" }}>
                              <CloseOutlineIcon />
                              &nbsp;{label}
                            </span>
                          );
                        }
                        return label;
                      }}
                      label={elm.label}
                      labelKey={"name"}
                      valueKey={"id"}
                      options={options}
                      disabled={disabled}
                      onChange={() => clearDependentStates(elm)}
                    />
                  );
                  break;
                }

                case FILTER_SEPARATOR: {
                  resultElm = (
                    <SeparatorLine
                      key={`separator-${index}-${Math.random()}`}
                      size={2}
                    />
                  );
                  break;
                }
              }

              return <div key={`element-${index}`}>{resultElm}</div>;
            })}
          </FormRequired>
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default UniversalFilters;
