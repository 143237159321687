import {
  IActivityAdd,
  IAddTaskState,
  ITTRecurringSettings,
  IChangePass,
  IContinuityReportRequestData,
  ICreateAddress,
  ICreateBrand,
  ICreateCategory,
  ICreateChain,
  ICreateCustomer,
  ICreateLocation,
  ICreateNotification,
  ICreateProduct,
  ICreateProject,
  ICreateProjectProduct,
  ICreateQuestion,
  ICreateRole,
  ICreateUser,
  ITimeTableState,
  IEditUser,
  IFilterOrder,
  IFilterPaginate,
  IFiltersAddresses,
  IFiltersLocations,
  IFiltersPhotoReview,
  IFiltersProjects,
  IFiltersUsers,
  IFiltersVisitsList,
  IFilterTimeTable,
  IGetActivities,
  IGetAddresses,
  IGetBrands,
  IGetCategories,
  IGetChains,
  IGetCustomers,
  IGetLocations,
  IGetNotifications,
  IGetProducts,
  IGetProjectLocations,
  IGetProjectProducts,
  IGetProjects,
  IGetProjectTasks,
  IGetQuestions,
  IGetRoles,
  IGetUsers,
  IHeaderViewResponse,
  INotification,
  IPhotoReviewErrors,
  IPhotoReviewModal,
  IPhotoReviewRequestData,
  IProjectImportRequestData,
  IProjectSettings,
  ISelectViewResponse,
  IGetLocationCustomers,
  IMailerRequest,
} from "./models";
import { IFilters } from "../views/projects/components/visitsReport/SelectVisitsList";
import { deepClone, toPHPTimestamp } from "./helpers";

const currentDate = new Date();

export const _paginate30 = {
  requestOrder: {
    field: undefined,
    order: undefined,
  },
  requestPaginate: {
    page: 1,
    limit: 30,
  },
};

const _dateRange = {
  dateRange: {
    dateFrom: "",
    dateTo: "",
  },
};

const filtersState: Partial<IFilters> = {
  startDate: 0,
  endDate: 0,
  locationName: "",
  nip: "",
  street: "",
  city: "",
  community: "",
  district: "",
  voivodeshipId: "",
  networkId: "",
  groupIds: [],
  userRoleIds: [],
  projectId: "",
  statusId: "",
  locationId: "",
  locationCode: "",
  locationCustomerCode: "",
  locationIncrementId: "",
  taskId: "",
  voivodeshipIds: [],
  networkIds: [],
  statusIds: [],
  locationIds: [],
  taskIds: [],
};

export const projectSettings: IProjectSettings = {
  isEnableCreateLocation: false,
  isEnableEditLocation: false,
  isEnableVisitOutOfPlan: false,
  defaultDistributorId: "",
  id: "",
  isEnableStoreDocument: false,
  isEnableGlobalTimer: false,
  isEnableBill: false,
  isEnableDocumentDump: true,
  isEnableInvoice: false,
  isEnableRecipient: true,
  isEnablePayer: true,
  isEnableNip: false,
  isEnableOrder: false,
  isEnableTimer: false,
  isEnableOneTimeVisits: false,
  isEnableSynchroGps: false,
  isEnableTaskGrouping: true,
  isVisitOutOfPlanByLocation: false,
  isEnableDownloadPhotoFromDisk: true,
  isEnableOnlyLocationFromActivity: false,
  isEnableSaveDocumentWithoutPrint: false,
  isEnableItemNetworkListing: false,
  isEnableQuestionNetworkListing: false,
  isEnableItemLevelNetworkListing: false,
  isEnableItemHighlightNetworkListing: false,
  isEnableSortByHighlight: false,
  isEnableCountKilometers: false,
  salePrefix: "",
  numerationType: "",
  numberDocumentPattern: "",
  showDocumentCount: 0,
  numberOrderPattern: "",
  showOrderCount: 0,
  photoQuality: 0,
  photoResolution: 0,
  photoMaxSize: 0,
  photoFilenamePattern: "",
  calcDocumentType: "",
  prepareDocumentCount: 0,
  improveExpirationDays: 0,
  isRequiredOnLocationCommunity: true,
  isRequiredOnLocationDistrict: true,
  isRequiredOnLocationNetwork: true,
  isRequiredOnLocationNip: true,
  isRequiredOnLocationPostcode: true,
  isRequiredOnLocationType: true,
  isRequiredOnLocationVoivodeship: true,
  isPhotoWatermarkEnabled: false,
  improveAvailableDays: 0,
  paymentType: "",
  deviceType: "",
  isPhotoWatermarkOutside: false,
  isEnableHistoryQuestionAnswers: false,
  gpsDistance: undefined,
  antennaDistance: undefined,
};

export const createProjectState: ICreateProject = {
  name: "",
  customerId: "",
  projectStatusId: "",
  endDate: null,
  startDate: null,
  projectSettings: projectSettings,
};

export const createAddressState: ICreateAddress = {
  street: "",
  houseNumber: "",
  apartmentNumber: "",
  postCode: "",
  city: "",
  country: "",
  voivodeship: "",
  district: "",
  community: "",
  verified: false,
  coords: null,
};

export const createUserDetails = {
  idCard: "",
  pesel: "",
  email: "",
  phone: "",
};

const createEditUserStateCommonData = {
  firstName: "",
  lastName: "",
  username: "",
  userStatusId: "",
  roles: [],
  ...createUserDetails,
  customerId: "",
  userWebReportingStatusId: "",
  salePrefix: "",
};

export const createUserState: ICreateUser = {
  password: "",
  repeatPassword: "",
  ...createEditUserStateCommonData,
};

export const editUserState: IEditUser = {
  ...createEditUserStateCommonData,
  salePrefix: "",
  apkVersion: "",
  ipaVersion: "",
  id: "",
};

export const passChangeState: IChangePass = {
  oldPassword: "",
  newPassword: "",
  repeatNewPassword: "",
};

export const getUsersState: IGetUsers = {
  username: "",
  email: "",
  userStatusId: "",
  roleId: "",
  userWebReportingStatusId: "",
  apkVersion: "",
  ..._paginate30,
};

export const getOrganizationsState = {
  countryId: "",
  name: "",
  ..._paginate30,
};

export const filtersUsersState: IFiltersUsers = {
  username: "",
  userStatusId: "",
  roleId: "",
  userWebReportingStatusId: "",
};

export const getLocationsState: IGetLocations = {
  name: "",
  description: "",
  addressId: "",
  street: "",
  networkId: "",
  voivodeshipId: "",
  city: "",
  community: "",
  district: "",
  nip: "",
  locationCustomerCode: "",
  code: "",
  ..._paginate30,
};

export const filtersLocationsState: IFiltersLocations = {
  name: "",
  description: "",
  networkId: "",
  street: "",
};

export const filtersAddressesState: IFiltersAddresses = {
  street: "",
  houseNumber: "",
  apartmentNumber: "",
  postcode: "",
  city: "",
  country: "",
  voivodeshipId: "",
  district: "",
  community: "",
};

export const getAddressesState: IGetAddresses = {
  id: "",
  street: "",
  houseNumber: "",
  apartmentNumber: "",
  postcode: "",
  city: "",
  country: "",
  voivodeshipId: "",
  district: "",
  community: "",
  search: "",
  ..._paginate30,
};

export const getProjectsState: IGetProjects = {
  name: "",
  customerId: "",
  projectStatusId: "",
  startDate: "",
  endDate: "",
  ..._paginate30,
};

export const filtersProjectsState: IFiltersProjects = {
  name: "",
  customerId: "",
  projectStatusId: "",
  startDate: "",
  endDate: "",
};

export const createLocationState: ICreateLocation = {
  id: "",
  projectLocationNetworkId: "",
  networkLocationCode: "",
  phone: "",
  name: "",
  description: "",
  networkId: "",
  code: "",
  contactPerson: "",
  email: "",
  invoiceName: "",
  locationTypeId: "",
  addressId: {
    id: "",
    name: "",
  },
  nip: "",
  payerAddressId: {
    id: "",
    name: "",
  },
  payerName: "",
  recipientAddressId: {
    id: "",
    name: "",
  },
  recipientName: "",
  locationCustomers: [],
  locationCustomerRegions: [],
  incrementId: 0,
};

export const getProjectsLocationsState: IGetProjectLocations = {
  networkId: "",
  reportingUserId: "",
  locationCustomerCode: "",
  street: "",
  name: "",
  city: "",
  district: "",
  voivodeshipId: "",
  community: "",
  nip: "",
  roleId: "",
  status: null,
  ..._paginate30,
};

export const getActivitesState: IGetActivities = {
  name: "",
  description: "",
  ..._paginate30,
};

export const addActivityState: IActivityAdd = {
  name: "",
  description: "",
  activityTypeId: "",
  activityGroupId: "",
  scheduleDate: null,
  endDate: null,
};

export const getProjectTasksState: IGetProjectTasks = {
  name: "",
  description: "",
  taskStatusId: "",
  ..._paginate30,
};

export const recurringSettingsState: ITTRecurringSettings = {
  type: 1,
  startDate: 0,
  endDate: 0,
  days: [],
};

export const singleSettingsState = { startDate: 0 };
export const manualSettingsState = { days: [] };

export const createTimeTableState: ITimeTableState = {
  type: 0,
  isApproved: true,
  default: false,
  singleSettings: deepClone(singleSettingsState),
  manualSettings: deepClone(manualSettingsState),
  recurringSettings: [deepClone(recurringSettingsState)],
  projectId: "",
  taskId: "",
  userId: "",
  locationId: "",
};

export const createTaskState: IAddTaskState = {
  name: "",
  description: "",
  taskStatusId: "",
  expirationDays: 0,
  isExpirationEndOfWeek: false,
  timeTable: createTimeTableState,
  scheduleDate: undefined,
  endDate: undefined,
};

export const getBrandsState: IGetBrands = {
  name: "",
  ..._paginate30,
};

export const createBrandState: ICreateBrand = {
  name: "",
};

export const getProductsState: IGetProducts = {
  customerId: "",
  name: "",
  gtin: "",
  brandId: "",
  itemCategoryId: "",
  ..._paginate30,
};

export const getCategoriesState: IGetCategories = {
  customerId: "",
  name: "",
  ..._paginate30,
};

export const createProductState: ICreateProduct = {
  amount: "",
  brandId: "",
  currency: "",
  volume: "",
  gtin: "",
  countInBag: 1,
  height: 0,
  length: 0,
  width: 0,
  name: "",
  itemUnitId: "",
  itemGroupId: "",
  itemVatRateId: "",
  customerId: "",
  volumeUnitId: "",
  itemCategoryId: "",
  assignToProjects: [],
};

export const createProjectProductState: ICreateProjectProduct = {
  marker: "",
  name: "",
  customerId: "",
  gtin: "",
  countInBag: 1,
  brandId: "",
  itemGroupId: "",
  itemCategoryId: "",
  itemUnitId: "",
  itemVatRateId: "",
  photo: "",
  price: {
    amount: 0,
    tax: 0,
    amountGross: 0,
    discount: 0,
    amountTotal: 0,
    taxTotal: 0,
    amountGrossTotal: 0,
    discountTotal: 0,
    currency: "",
    amountInWords: "",
  },
  dimension: {
    length: 0,
    width: 0,
    height: 0,
  },
};

export const createCategoryState: ICreateCategory = {
  name: "",
  customerId: "",
};

export const createChainState: ICreateChain = {
  name: "",
};

export const getChainsState: IGetChains = {
  name: "",
  ..._paginate30,
};

export const getProjectProductsState: IGetProjectProducts = {
  customerId: "",
  name: "",
  gtin: "",
  brandId: "",
  itemGroupId: "",
  itemUnitId: "",
  itemVatRateId: "",
  ..._paginate30,
};

export const getRolesState: IGetRoles = {
  name: "",
  ..._paginate30,
};

export const getNotificationsState: IGetNotifications = {
  subject: "",
  status: "",
  sourceType: "",
  sendChannel: "",
  ..._paginate30,
};

export const notificationState: INotification = {
  id: "",
  subject: "",
  status: "",
  body: "",
  iconPath: "",
  sourceType: "",
  sourceId: "",
  sendChannel: "",
  users: [],
  attachment: "",
  scheduleDate: "",
  isConfirmationRequired: true,
  scheduleDateTimestamp: parseInt((Date.now() / 1000).toFixed(0)),
  endDate: "",
  endDateTimestamp: parseInt((Date.now() / 1000).toFixed(0)),
  createFrom: "",
};

export const createNotificationState: ICreateNotification = {
  subject: "",
  body: "",
  sendChannel: "",
  sourceType: "",
  sourceId: "",
  scheduleDate: "",
};

export const createRoleState: ICreateRole = {
  name: "",
  roleTypeId: "",
  isEnableWorkTimeHistory: false,
  hierarchyLevel: 0,
};

export const createQuestionState: ICreateQuestion = {
  name: "",
  customerId: "",
  questionTypeId: "",
  activityTypeId: "",
  description: "",
  isRequired: false,
  pointsValue: 0,
  questionType: "",
  minValue: null,
  maxValue: null,
  answers: [],
  isEdit: true,
  assignToActivities: [],
  code: null,
};

export const getQuestionsState: IGetQuestions = {
  name: "",
  customerId: "",
  questionTypeId: "",
  activityTypeId: "",
  ..._paginate30,
};

export const createCustomerState: ICreateCustomer = {
  name: "",
  shortName: "",
  logo: "",
  addressId: "ca73d254-eea5-4c0b-badb-2f2bbb4193c6", // todo: ???
  code: "",
  customerType: "",
  customerTypeId: "",
  parentCustomerId: "",
  email: "",
  fax: "",
  phone: "",
  www: "",
  nip: "",
  repoDir: "",
  ftpAddr: "",
  ftpUser: "",
  ftpPass: "",
  ftpPort: "",
  isEnableWorkTimeHistory: false,
  orderFormat: "",
};

export const getCustomersState: IGetCustomers = {
  name: "",
  ..._paginate30,
};

export const filtersFormState: any = {
  header: { name: "" },
  countries: [],
  fields: [],
  actions: [],
};

export const defaultFilterPaginateState: IFilterPaginate = {
  requestPaginate: {
    page: 1,
    limit: 10,
  },
};

export const defaultFilterOrderState: IFilterOrder = {
  requestOrder: _paginate30.requestOrder,
};

export const timeTableFilterState: IFilterTimeTable = {
  isDefault: false,
  networkId: "",
  taskName: "",
  locationId: "",
  projectId: null,
  taskId: "",
  userId: "",
  ...defaultFilterPaginateState,
};

export const headerViewResponseState: IHeaderViewResponse = {
  header: {
    name: "",
    subName: "",
  },
};

export const selectViewResponseState: ISelectViewResponse = {
  options: [],
  value: null,
  disabled: false,
};

export const photoReviewModalState: IPhotoReviewModal = {
  open: false,
  photoSimple: null,
  visit: null,
  toggler: 0,
  prevId: null,
  nextId: null,
};

export const photoReviewRequestDataState: IPhotoReviewRequestData = {
  projectIds: [],
  questionIds: [],
  activityIds: [],
  locationCode: "",
  visitId: null,
  networkIds: [],
  roleIds: [],
  locationIds: [],
  userRoleIds: [],
  dateRange: {
    dateFrom: "",
    dateTo: "",
  },
  ..._paginate30,
};

export const getLocationCustomersState: IGetLocationCustomers = {
  code: "",
  ..._paginate30,
};

export const photoReviewFilterState: Partial<IFiltersPhotoReview> = {
  ...filtersState,
  projects: {
    value: "",
    disabled: false,
    options: [],
  },
  questions: {
    value: "",
    disabled: false,
    options: [],
  },
  tasks: {
    value: "",
    disabled: false,
    options: [],
  },
  merchRoles: {
    value: "",
    disabled: false,
    options: [],
  },
  users: {
    value: "",
    disabled: false,
    options: [],
  },
  hasFeedback: {
    value: "",
    disabled: false,
    options: [],
  },
  ..._dateRange,
};

export const photoReviewErrorDef: IPhotoReviewErrors = {
  KPI: {},
  SLA: {},
};

export const visitsListFilterDefault: IFiltersVisitsList = {
  reportingRoles: false,
  locationCode: "",
  locationCustomerCode: "",
  locationId: "",
  locationIds: [],
  locationName: "",
  locationIncrementId: "",
  nip: "",
  networkId: "",
  networkIds: [],
  statusId: "",
  statusIds: [],
  startDate: 0,
  endDate: 0,
  projectId: "",
  voivodeshipId: "",
  voivodeshipIds: [],
  city: "",
  street: "",
  district: "",
  community: "",
  groupIds: [],
  userRoleIds: [],
  taskId: "",
  taskIds: [],
  ..._paginate30,
};

export const projectImportRequestDataDefault: IProjectImportRequestData = {
  ..._paginate30,
  importType: "",
  importAction: "",
  importStatus: "",
  dateFrom: "",
  dateTo: "",
  exports: [],
};

export const continuityReportRequestDateDefault: IContinuityReportRequestData =
  {
    customerId: "",
    locationId: "",
    dateTimestamp: toPHPTimestamp(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getTime()
    ),
    ..._paginate30,
  };

export const VisitReportListRequest = {
  ..._paginate30,
};

export const MailerRequestState: IMailerRequest = {
  ..._paginate30,
  subject: "",
  userId: "",
  sendStatus: "",
  sendChannel: "",
  confirmationRequired: 0,
  confirmed: 0,
  dateFrom: 0,
  dateTo: 0,
};
