import React, { memo, useContext } from "react";
import { FCC } from "utils/models";
import {
  IVisitEditErrorContext,
  VisitEditErrorContext,
} from "../../VisitEditContextWrapper";
import FormErrorMessage from "rsuite/FormErrorMessage";

interface IQuestionErrors {
  refQuestionId: string;
}

const QuestionErrors: FCC<IQuestionErrors> = (props) => {
  const { errors } = useContext(
    VisitEditErrorContext
  ) as IVisitEditErrorContext;

  const messages = errors?.[props.refQuestionId];

  if (!messages?.length) {
    return <></>;
  }

  return (
    <FormErrorMessage show={!!messages.length} placement={"bottomEnd"}>
      {messages.map((msg, i) => (
        <div key={`err-${props.refQuestionId}-${i}`}>{msg}</div>
      )) ?? ""}
    </FormErrorMessage>
  );
};

export default memo(QuestionErrors);
