import React, {
  CSSProperties,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import DashboardConnection from "../../../utils/connections/dashboard";
import { _paginate30 } from "../../../utils/states";
import {
  Checkbox,
  ColumnProps,
  Dropdown,
  IconButton,
  Input,
  InputGroup,
  Panel,
  Popover,
  Table,
  Whisper,
} from "rsuite";
import IconSvg from "../../../global/atoms/IconHelper";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import BadgeStatus from "../../../global/atoms/badge/BadgeStatus";
import SeparatorEmpty from "../../../global/atoms/separators/SeparatorEmpty";

import { RowDataType } from "rsuite/esm/Table";
import { handleSortColumn } from "../../../utils/helpers";
import { IGetPaginationBase } from "../../../utils/models";
import Pagination from "../../../global/pagination/Pagination";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import Spinner from "../../../global/atoms/Spinner/Spinner";
import EditIcon from "@rsuite/icons/Edit";
import FunnelIcon from "@rsuite/icons/Funnel";
import { Helmet } from "react-helmet-async";
import ReloadIcon from "@rsuite/icons/Reload";
import { cloneDeep, isEqual } from "lodash";
import MenuIcon from "@rsuite/icons/Menu";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { ValueType } from "rsuite/esm/InputPicker/InputPicker";
import SearchIcon from "@rsuite/icons/Search";
import CloseIcon from "@rsuite/icons/Close";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import SortableList from "../../../global/atoms/dnd/SortableList";
import { DragHandle } from "../../../global/atoms/SortableItem";

interface IVStatus {
  id: string;
  name: string;
  status: string;
  count: number;
  color: string;
}

interface IVisitData {
  locationName: string;
  projectName: string;
  networkName: string;
  locationAddressCityName: string;
  startDate: string;
  reportingUserName: string;
  status: { name: string; color: string };
  projectId: string;
  id: string;
}

interface IListData {
  count: number;
  data: Array<IVisitData>;
}

interface IStatusCounter {
  status: IVStatus;
  statusCounter: number;
}

interface IColumns {
  name: string;
  label: string | ReactElement;
  renderCell?: (rowData: RowDataType<IVisitData>) => React.ReactElement;
  props?: ColumnProps;
  filter?: any;
  filterKey?: string;
  multiselect?: boolean;
  defaultVisible?: boolean;
}

interface IFilter extends IGetPaginationBase {
  status?: string;
  locationIds?: Array<string>;
  projectIds?: Array<string>;
}

const defaultFilter: IFilter = {
  ..._paginate30,
};

// Function to transform table data
const transformDataForExcel = (
  data: Array<IVisitData>,
  columns: Array<IColumns>,
  selectedColumns: Array<string>
) => {
  return data.map((row) => {
    const transformedRow: { [key: string]: any } = {};
    columns.forEach((column: IColumns) => {
      if (selectedColumns.includes(column.name)) {
        const _cellData = row[column.name];
        if (_cellData instanceof Object) {
          transformedRow[column.label as string] = _cellData.name;
        } else {
          transformedRow[column.label as string] = _cellData;
        }
      }
    });
    return transformedRow;
  });
};

const exportToExcel = (
  data: Array<IVisitData>,
  columns: Array<IColumns>,
  selectedColumns: Array<string>
) => {
  const transformedData = transformDataForExcel(data, columns, selectedColumns);
  const worksheet = XLSX.utils.json_to_sheet(transformedData);

  // Calculate column widths
  const maxLengths = transformedData.reduce((acc: Array<number>, row) => {
    Object.keys(row).forEach((key, index) => {
      const value = row[key];
      acc[index] = Math.max(acc[index] || 0, value.length);
    });
    return acc;
  }, []);

  worksheet["!cols"] = maxLengths.map((length) => ({ wch: length + 2 }));
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Statusy");
  XLSX.writeFile(
    workbook,
    `Wizyty niezrealizowane ${dayjs().format("YYYY-MM-DD HHmmss")}.xlsx`
  );
};

const DashboardVisitsList: FunctionComponent = () => {
  const [data, setData] = useState<IListData | undefined>();
  const [statusesCounterData, setStatusesCounterData] = useState<
    Array<IStatusCounter> | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [statusesLoading, setStatusesLoading] = useState(false);
  const [filtersData, setFiltersData] = useState<any>();
  const [filters, setFilters] = useState<IFilter>(defaultFilter);
  const [selectedFilters, setSelectedFilters] =
    useState<IFilter>(defaultFilter);

  useEffect(() => {
    setLoading(true);
    DashboardConnection.getUnrealizedVisits(filters).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  }, [filters]);

  useEffect(() => {
    setStatusesLoading(true);
    DashboardConnection.getUnrealizedFilterForm().then((response) => {
      setFiltersData(response.data);
      setStatusesCounterData(response.data.visitsStatuses.options);
      setStatusesLoading(false);
    });
  }, []);

  const outLineActive = (color: string): CSSProperties => ({
    border: "1px solid",
    borderColor: color,
    borderRadius: "15px",
  });

  const dataColumns: Array<IColumns> = [
    {
      name: "locationName",
      label: "Lokalizacja",
      props: { flexGrow: 2, sortable: true, minWidth: 300, resizable: true },
      filter: "locations",
      filterKey: "locationIds",
      multiselect: true,
      defaultVisible: true,
    },
    {
      name: "projectName",
      label: "Projekt",
      props: { flexGrow: 2, sortable: true, resizable: true },
      filter: "projects",
      filterKey: "projectIds",
      defaultVisible: true,
    },
    {
      name: "locationAddressCityName",
      label: "Miejscowość",
      props: { sortable: true, fullText: true, resizable: true },
      defaultVisible: true,
    },
    {
      name: "locationAddress",
      label: "Adres",
      props: { fullText: true, resizable: true },
    },
    {
      name: "startDate",
      label: "Data Wizyty",
      props: { flexGrow: 1, sortable: true },
      defaultVisible: true,
    },
    {
      name: "networkName",
      label: "Sieć",
    },

    {
      name: "reportingUserName",
      label: "Raportujący",
      props: { flexGrow: 1, sortable: true },
      defaultVisible: true,
    },
    {
      name: "status",
      label: "Status",
      renderCell: (rowData: RowDataType<IVisitData>) => {
        return (
          <BadgeStatus color={rowData.status.color}>
            {rowData.status.name}
          </BadgeStatus>
        );
      },
      defaultVisible: true,
      props: { fixed: "right", sortable: true, width: 150 },
    },
  ];

  const [dataColumnsState, setDataColumnsState] = useState(dataColumns);
  const actionsColumn = {
    name: "actions",
    label: (
      <CloseOutlineIcon
        style={{ cursor: "pointer" }}
        color={!isEqual(defaultFilter, filters) ? "#E09616" : "#CCCC"}
        onClick={() => {
          const _toSet = {
            projectIds: undefined,
            locationIds: undefined,
            requestOrder: {
              field: undefined,
              order: undefined,
            },
          };
          setSelectedColumns(dataColumnsState.map((c) => c.name));
          setFilters((f) => ({
            ...f,
            ..._toSet,
          }));
          setSelectedFilters((f) => ({ ...f, ..._toSet }));
        }}
      />
    ),
    renderCell: (rowData: any) => {
      return (
        <EditIcon
          style={{ cursor: "pointer" }}
          color={"#E09616"}
          onClick={() => {
            window.open(
              `/projects/${rowData.projectId}/visits/${rowData.id}/edit`,
              "_blank"
            );
          }}
        />
      );
    },
  };

  const renderTableColumnsChooser = () => {
    const handleCheckboxChange = (
      value: ValueType | undefined,
      checked: boolean
    ) => {
      setSelectedColumns((prevSelected) => {
        if (checked) {
          return [...prevSelected, value];
        } else {
          return prevSelected.filter((item) => item !== value);
        }
      });
    };
    const onSortEnd = (sortedData) => {
      setDataColumnsState(sortedData);
    };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "200px",
          cursor: "pointer",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <div>Kolumny</div>
          <div>
            <CloseOutlineIcon
              style={{ cursor: "pointer" }}
              color={
                !isEqual(dataColumnsState, dataColumns) ? "#E09616" : "#CCCC"
              }
              onClick={() => {
                setDataColumnsState(dataColumns);
                setSelectedColumns(
                  dataColumnsState
                    .filter((c) => c.defaultVisible)
                    .map((c) => c.name)
                );
              }}
            />
          </div>
        </div>

        <SortableList
          onSortEnd={onSortEnd}
          data={dataColumnsState}
          idKeyName={"name"}
          disabled={false}
          dragHandleActivator={true}
          mapFunction={(column, index) => (
            <>
              <div
                key={"div-" + index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Checkbox
                  key={column.name}
                  value={column.name}
                  checked={selectedColumns.includes(column.name)}
                  onChange={handleCheckboxChange}>
                  {column.label}
                </Checkbox>
                <DragHandle apperace={"ghost"} size={"xs"} />
              </div>
            </>
          )}
        />
      </div>
    );
  };
  const handleFilterChange = (
    value: ValueType,
    checked: boolean,
    filterKey: string | undefined
  ) => {
    if (!filterKey) return;
    let filterDataCurrent: Array<string> =
      cloneDeep(selectedFilters?.[filterKey]) || [];
    if (checked) filterDataCurrent.push(value as unknown as string);
    else filterDataCurrent = filterDataCurrent.filter((item) => item !== value);
    setSelectedFilters((f) => ({
      ...f,
      [filterKey]: filterDataCurrent,
    }));
  };

  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    dataColumnsState.filter((c) => c.defaultVisible).map((c) => c.name)
  );
  const [textFilter, setTextFilter] = useState<string[]>(
    dataColumnsState.filter((c) => c.defaultVisible).map((c) => c.name)
  );

  const tableHeaderMenu = () => (
    <Whisper
      placement="bottomEnd"
      trigger="click"
      speaker={
        <Popover>
          <Dropdown.Menu style={{ width: 200 }}>
            <Dropdown.Item
              onClick={() => {
                exportToExcel(
                  data?.data ?? [],
                  dataColumnsState,
                  selectedColumns
                );
              }}
              icon={<FileDownloadIcon />}>
              Eksport do Excel
            </Dropdown.Item>
            <Dropdown.Separator />
          </Dropdown.Menu>
          {renderTableColumnsChooser()}
        </Popover>
      }>
      <MenuIcon />
    </Whisper>
  );
  const renderColumnFilter = (column: IColumns) => (
    <Whisper
      onClick={(event) => {
        event.stopPropagation();
      }}
      onClose={() => {
        setTextFilter((tf) => ({
          ...tf,
          [column.filterKey || ""]: "",
        }));
        setFilters((f) => ({
          ...f,
          ...selectedFilters,
        }));
      }}
      placement="bottomStart"
      trigger="click"
      enterable
      controlId={column.name}
      speaker={
        <Popover
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              {column.label}
              <div style={{ display: "flex", gap: "5px" }}>
                <IconButton
                  size={"xs"}
                  appearance={"ghost"}
                  icon={<CloseOutlineIcon />}
                  onClick={() => {
                    setSelectedFilters((f) => ({
                      ...f,
                      [column.filterKey ?? ""]: [],
                    }));
                  }}
                />
                <IconButton
                  size={"xs"}
                  appearance={"ghost"}
                  icon={<ReloadIcon />}
                  onClick={() => {
                    setFilters((f) => ({
                      ...f,
                      ...selectedFilters,
                    }));
                  }}
                />
              </div>
            </div>
          }
          onClick={(event) => event.stopPropagation()}>
          <InputGroup style={{ marginBottom: "5px" }} size={"xs"}>
            <Input
              placeholder={"wyszukaj..."}
              value={textFilter[column.filterKey || ""]}
              onChange={(value) => {
                setTextFilter((tf) => ({
                  ...tf,
                  [column.filterKey || ""]: value,
                }));
              }}
            />
            {textFilter?.[column.filterKey || ""] && (
              <InputGroup.Addon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTextFilter((tf) => ({
                    ...tf,
                    [column.filterKey || ""]: "",
                  }));
                }}>
                <CloseIcon color={"#E09616"} />
              </InputGroup.Addon>
            )}
            {!textFilter?.[column.filterKey || ""] && (
              <InputGroup.Addon>
                <SearchIcon color={"#E09616"} />
              </InputGroup.Addon>
            )}
          </InputGroup>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "300px",
              overflow: "auto",
            }}>
            {(
              (filtersData?.[column.filter]?.options ?? []).filter((o) =>
                textFilter[column.filterKey ?? ""]
                  ? o.name
                      .toLowerCase()
                      .includes(
                        textFilter[column.filterKey || ""]?.toLowerCase()
                      )
                  : true
              ) ?? []
            ).map((option) => (
              <Checkbox
                value={option.id}
                checked={(
                  selectedFilters?.[column?.filterKey ?? ""] ?? []
                ).includes(option.id)}
                style={{}}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onChange={(value, checked) => {
                  handleFilterChange(value, checked, column?.filterKey);
                }}
                key={option.id}>
                {option.name}
              </Checkbox>
            ))}
            <SeparatorEmpty />
          </div>
        </Popover>
      }>
      <FunnelIcon
        color={selectedFilters?.[column?.filterKey ?? ""] ? "#E09616" : ""}
      />
    </Whisper>
  );
  const renderAvailableStatuses = (s: IStatusCounter) => {
    const internalStatusStyle = {
      fontFamily: "Poppins, sans-serif",
      padding: "5px",
      fontWeight: 500,
      letterSpacing: "0.6px",
    };
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexFlow: "flex-start",
            gap: "5px",
            paddingRight: "10px",
            cursor: "pointer",
            ...(filters.status == s.status.status
              ? outLineActive(s.status.color)
              : {}),
          }}
          onClick={() => {
            if (filters.status != s.status.status || filters.status == "") {
              setFilters({ ...filters, status: s.status.status });
            } else {
              setFilters({ ...filters, status: "" });
            }
          }}>
          <span
            style={{
              flexGrow: 1,
              ...internalStatusStyle,
              color: s.status.color,
              borderColor: s.status.color,
            }}>
            {IconSvg(faDotCircle, s.status.name, false, s.status.color)}
            {s.status.name}
          </span>
          <span
            key={s.status.id}
            style={{
              fontSize: "10px",
              fontWeight: 700,
              backgroundColor: s.status.color,
              border: "1px solid",
              borderColor: s.status.color,
              borderRadius: "10px",
              padding: "1px 5px 0px 5px",
              color: "white",
            }}>
            {s.statusCounter}
          </span>
        </div>
      </>
    );
  };
  if (statusesLoading) return <Spinner />;
  return (
    <>
      <Panel
        bordered
        style={{ backgroundColor: "white", padding: "10px" }}
        bodyFill>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}>
            <div style={{ width: "200px" }}>Stan realizacji wizyt:</div>
            {(statusesCounterData ?? []).map((s) => {
              return renderAvailableStatuses(s);
            })}
            <div style={{ display: "flex", alignItems: "center" }}>
              <CloseOutlineIcon
                style={{
                  cursor: "pointer",
                  color: filters.status ? "#E09616" : "#CCCC",
                  fontSize: "20px",
                }}
                onClick={() => {
                  if (filters.status) setFilters({ ...filters, status: "" });
                }}
              />
            </div>
          </div>
          {tableHeaderMenu()}
        </div>
        <SeparatorEmpty />
        <div style={{ maxHeight: "500px", minHeight: "300px" }}>
          <Table
            rowHeight={35}
            cellBordered
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            {dataColumnsState
              .filter((c) => selectedColumns.includes(c.name))
              .map((column) => {
                return (
                  <>
                    <Table.Column {...column.props}>
                      <Table.HeaderCell>
                        {column.label}
                        {column?.filter && renderColumnFilter(column)}
                      </Table.HeaderCell>
                      {column.renderCell ? (
                        <Table.Cell dataKey={column.name}>
                          {column.renderCell}
                        </Table.Cell>
                      ) : (
                        <Table.Cell dataKey={column.name} />
                      )}
                    </Table.Column>
                  </>
                );
              })}
            <Table.Column fixed="right" width={50} align="center">
              <Table.HeaderCell>{actionsColumn.label}</Table.HeaderCell>
              <Table.Cell>{actionsColumn.renderCell}</Table.Cell>
            </Table.Column>
          </Table>
          <SeparatorEmpty />
        </div>
        <Pagination
          count={data?.count ?? 0}
          page={filters.requestPaginate.page.toString()}
          limit={filters.requestPaginate.limit.toString()}
          setState={setFilters}
          limitOptions={[10, 30, 100, 1000]}
        />
      </Panel>
      <div style={{ marginBottom: "10px" }}>&nbsp;</div>
      <Helmet>
        <style>{`
        .rs-checkbox {
          height: 25px;
        }
        `}</style>
      </Helmet>
    </>
  );
};

export default DashboardVisitsList;
