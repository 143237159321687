import React, { FunctionComponent, useEffect, useState } from "react";
import { generatePath, useParams } from "react-router-dom";
import Pagination from "global/pagination/Pagination";
import InstancesConnection from "utils/connections/instances";
import BadgeStatus from "global/atoms/badge/BadgeStatus";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import ActionsContainer from "../../../../../global/atoms/ActionsContainer";
import {
  BatchActionDropdownItem,
  DeleteSingleButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from "../../../../../utils/toggleToDeleteHelper";
import {
  getProjectIdFromUrl,
  handleSortColumn,
  handleToast,
} from "../../../../../utils/helpers";
import ButtonListRedirect from "../../../../../global/atoms/ButtonListRedirect";
import { IFiltersVisitsList } from "../../../../../utils/models";
import ScColumn from "../../../../../global/table/ScColumn";
import { visitsListFilterDefault } from "../../../../../utils/states";
import VisitsModalAdd from "../visitsAdd/VisitsModalAdd";
import UniversalFilters, {
  FILTER_INPUT_DATE,
  FILTER_INPUT_TEXT,
  FILTER_MULTI_SELECT,
  FILTER_SELECT,
  FILTER_SEPARATOR,
} from "../../../../../global/filters/UniversalFilters";
import OptionsDropdown from "../../../../../global/atoms/OptionsDropdown";
import PlusIcon from "@rsuite/icons/Plus";
import { Dropdown, Table } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import { RowDataType } from "rsuite/esm/Table";
import LocationProjectModalAdd from "../../locations/LocationProjectModalAdd";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import { confirmModalHandler } from "../../../../../redux/store";
import TableIcon from "@rsuite/icons/Table";

interface IDataItem {
  expirationDays: number;
  id: string;
  locationName: string;
  locationAddress: string;
  networkName: string;
  projectId: string;
  projectName: string;
  reportingUserName: string;
  startDate: string;
  insertDate: string;
  status: {
    color: string;
    id: string;
    isDefault: boolean;
    name: string;
    status: string;
  };
  users: Array<{
    userId: string;
    userName: string;
    roleId: string;
    roleName: string;
    roleLevel: number;
    isReporting: boolean;
  }>;
}

interface ITableOptions {
  reportingRoles?: boolean;
}

interface IData {
  count: number;
  data: Array<IDataItem>;
}

interface IVisitFilterForm {
  disabledElements: Array<string>;
  hiddenElements: Array<string>;
  tasks: {
    options: Array<{ id: string; name: string }>;
    selected: Array<string>;
  };
}

const VisitsList: FunctionComponent = () => {
  const [data, setData] = useState<IData>({ data: [], count: 0 });

  const defaultFilters: IFiltersVisitsList = visitsListFilterDefault;
  const [filters, setFilters] = useState<IFiltersVisitsList>(defaultFilters);
  const [filtersForm, setFiltersForm] = useState<IVisitFilterForm>();
  const [tableOptionsState, setTableOptionsState] = useState<ITableOptions>();
  const [chosenVisits, setChosenVisits] = useState<Array<string>>([]);
  const [refLocationId, setRefLocationId] = useState<string>("");
  const [visitsStatusesApprovalOptions, setVisitsStatusesApprovalOptions] =
    useState<any>([]);
  const { id } = useParams<{ id: string }>();
  const [modalAddOpen, setModalAddOpen] = useState<boolean>(false);
  const [modalNewLocationAddOpen, setModalNewLocationAddOpen] =
    useState<boolean>(false);

  const [triggerLoad, setTriggerLoad] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (refLocationId) {
      setModalAddOpen(true);
      setModalNewLocationAddOpen(false);
    }
  }, [refLocationId]);

  const handleDeleteSelected = (deleteId: string) => {
    let toDelete = chosenVisits.map((id) => ({ id: id }));
    if (deleteId !== undefined) {
      toDelete = [{ id: deleteId }];
    }
    handleToast(InstancesConnection.batchDelete(id, toDelete)).then(() => {
      setChosenVisits([]);
      setTriggerLoad(Date.now());
    });
  };
  const handleApproveSelected = (status: string) => {
    const toApprove = chosenVisits.map((id) => ({ id: id }));
    handleToast(InstancesConnection.batchApprove(status, toApprove)).then(
      () => {
        setChosenVisits([]);
        setTriggerLoad(Date.now());
      }
    );
  };
  const tableOptionsKey = `project-${getProjectIdFromUrl()}-visit-list-table-options`;
  useEffect(() => {
    const _storageState = JSON.parse(
      localStorage.getItem(tableOptionsKey) ?? "{}"
    );
    setTableOptionsState(_storageState);
    return () => {
      setTableOptionsState(undefined);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(tableOptionsKey, JSON.stringify(tableOptionsState));
  }, [tableOptionsState]);

  useEffect(() => {
    InstancesConnection.getApprovalStatuses(id).then((response) => {
      setVisitsStatusesApprovalOptions(response.data.statuses.options ?? []);
    });
  }, []);
  const isDisabledElement = (key: string) => {
    // @ts-ignore
    return (filtersForm?.disabledElements ?? []).includes(key);
  };
  const isHiddenElement = (key: string) => {
    // @ts-ignore
    return (filtersForm?.hiddenElements ?? []).includes(key);
  };
  const getRolesData = () => {
    const reportingUserOnly = !tableOptionsState?.reportingRoles;
    const rolesMap = new Map<
      string,
      { roleName: string; roleLevel: number; isReporting: boolean }
    >();
    data.data.forEach((item) => {
      item.users.forEach((user) => {
        if (!rolesMap.has(user.roleId)) {
          rolesMap.set(user.roleId, {
            roleName: user.roleName,
            roleLevel: user.roleLevel,
            isReporting: user.isReporting,
          });
        }
      });
    });
    let rolesArray = Array.from(
      rolesMap,
      ([roleId, { roleName, roleLevel, isReporting }]) => ({
        roleId,
        roleName,
        roleLevel,
        isReporting,
      })
    );

    if (reportingUserOnly) {
      rolesArray = rolesArray.filter((role) => role.isReporting);
    }

    rolesArray.sort((a, b) => {
      if (b.isReporting === a.isReporting) {
        return b.roleLevel - a.roleLevel;
      }
      return b.isReporting ? 1 : -1;
    });
    return rolesArray;
  };

  return (
    <>
      <HeaderButtons>
        <OptionsDropdown>
          <Dropdown.Item
            icon={<PlusIcon />}
            disabled={isDisabledElement("addNewButton")}
            onClick={() => setModalAddOpen(true)}>
            Dodaj wizytę poza planem
          </Dropdown.Item>
          <Dropdown
            title={`Zatwierdź zaznaczone (${chosenVisits.length})`}
            icon={<CheckOutlineIcon />}
            disabled={
              !visitsStatusesApprovalOptions.length || !chosenVisits.length
            }>
            {visitsStatusesApprovalOptions.map((option: any) => (
              <>
                <Dropdown.Item
                  style={{ width: "200px" }}
                  icon={<CheckOutlineIcon />}
                  onClick={confirmModalHandler(
                    `Czy na pewno chcesz zatwierdzić zaznaczone wizyty do: ${option.name}?`,
                    handleApproveSelected.bind(null, option.id)
                  )}>
                  {option.name}
                </Dropdown.Item>
              </>
            ))}
          </Dropdown>
          {!isHiddenElement("addNewLocationButton") && (
            <Dropdown.Item
              icon={<PlusIcon />}
              disabled={isDisabledElement("addNewButton")}
              onClick={() => {
                setModalNewLocationAddOpen(true);
              }}>
              Dodaj wizytę poza planem, w nowej lokalizacji
            </Dropdown.Item>
          )}
          <BatchActionDropdownItem
            callback={handleDeleteSelected}
            disabled={isDisabledElement("deleteSelectedButton")}
            chosenRows={chosenVisits}
            icon={<TrashIcon />}
            title={`Usuń zaznaczone (${chosenVisits.length})`}
            message={"Czy na pewno chcesz usunąć zaznaczone wizyty?"}
          />
        </OptionsDropdown>
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            data={data.data ?? []}
            rowExpandedHeight={220}
            fillHeight
            loading={loading}
            sortColumn={filters.requestOrder.field}
            rowHeight={50}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) => {
              handleSortColumn(dataKey, sortType, setFilters);
            }}>
            <ScColumn
              align={"left"}
              width={40}
              verticalAlign={"top"}
              fixed={"left"}>
              <Table.HeaderCell>
                <ToggleAllCheckbox
                  data={data.data}
                  state={chosenVisits}
                  setState={setChosenVisits}
                />
              </Table.HeaderCell>
              <Table.Cell width={100}>
                {(rowData: RowDataType<IDataItem>) => (
                  <ToggleSingleCheckbox
                    state={chosenVisits}
                    setState={setChosenVisits}
                    selectedId={rowData.id}
                  />
                )}
              </Table.Cell>
            </ScColumn>

            <Table.Column
              align="left"
              width={300}
              resizable
              sortable
              fixed={"left"}>
              <Table.HeaderCell>Lokalizacja</Table.HeaderCell>
              <Table.Cell dataKey="locationName">
                {(rowData: RowDataType<IDataItem>) => (
                  <>
                    <ButtonListRedirect
                      redirectUrl={generatePath(
                        "/projects/:id/visits/:visitId/edit",
                        {
                          id: id,
                          visitId: rowData.id,
                        }
                      )}>
                      {rowData.locationName}
                    </ButtonListRedirect>
                    <div
                      style={{
                        position: "absolute",
                        display: "block",
                        color: "rgba(134,134,134,0.48)",
                        fontSize: "0.7em",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}>
                      {rowData.locationAddress}
                    </div>
                  </>
                )}
              </Table.Cell>
            </Table.Column>
            {getRolesData().map((role) => (
              <>
                <Table.Column
                  width={200}
                  resizable
                  sortable={role.isReporting}
                  fullText>
                  <Table.HeaderCell title={role.roleName}>
                    {role.roleName}
                  </Table.HeaderCell>
                  <Table.Cell dataKey="reportingUserName">
                    {(rowData: RowDataType<IDataItem>) => {
                      const user = rowData.users.find(
                        (user) => user.roleId === role.roleId
                      );
                      return <>{user ? user.userName : "-"}</>;
                    }}
                  </Table.Cell>
                </Table.Column>
              </>
            ))}
            <Table.Column
              verticalAlign={"middle"}
              align="left"
              width={150}
              resizable
              sortable>
              <Table.HeaderCell>Sieć</Table.HeaderCell>
              <Table.Cell dataKey="networkName" />
            </Table.Column>
            <Table.Column align="left" minWidth={200} resizable fullText>
              <Table.HeaderCell>Zadanie</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => rowData.taskNames.join(", ")}
              </Table.Cell>
            </Table.Column>

            <ScColumn
              align="left"
              flexGrow={1}
              minWidth={150}
              resizable
              sortable>
              <Table.HeaderCell>Data utworzenia</Table.HeaderCell>
              <Table.Cell dataKey="startDate" />
            </ScColumn>

            <Table.Column
              align="left"
              flexGrow={1}
              minWidth={150}
              resizable
              sortable>
              <Table.HeaderCell>Data wysłania</Table.HeaderCell>
              <Table.Cell dataKey="insertDate">
                {(rowData: RowDataType<IDataItem>) => {
                  return rowData.insertDate ?? "-";
                }}
              </Table.Cell>
            </Table.Column>

            <ScColumn
              minWidth={150}
              flexGrow={1}
              align="right"
              sortable
              fixed={"right"}>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell dataKey="visitStatus">
                {(rowData: RowDataType<IDataItem>) => {
                  return (
                    <BadgeStatus color={rowData.status?.color}>
                      {rowData.status?.name}
                    </BadgeStatus>
                  );
                }}
              </Table.Cell>
            </ScColumn>

            <ScColumn align="center" width={60} fixed={"right"}>
              <Table.HeaderCell title={"Pokaż wszystkie kolumny z rolami"}>
                <TableIcon
                  style={{
                    color: tableOptionsState?.reportingRoles
                      ? "#E09616"
                      : "silver",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    console.log(tableOptionsState);
                    setTableOptionsState((s) => ({
                      ...s,
                      reportingRoles: !s?.reportingRoles,
                    }));
                  }}
                />
              </Table.HeaderCell>
              <Table.Cell>
                {(rowData: RowDataType<IDataItem>) => (
                  <ActionsContainer>
                    {
                      //@ts-ignore
                      filtersForm?.disabledElements !== undefined &&
                        !isDisabledElement("deleteSelectedButton") && (
                          <DeleteSingleButton
                            callback={handleDeleteSelected.bind(
                              null,
                              rowData.id
                            )}
                            form={filtersForm}
                          />
                        )
                    }
                  </ActionsContainer>
                )}
              </Table.Cell>
            </ScColumn>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={filters.requestPaginate.page.toString()}
            limit={filters.requestPaginate.limit.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={InstancesConnection.getInstances}
        formGet={InstancesConnection.getFiltersInstancesList}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={`projectVisitsList_1_${getProjectIdFromUrl()}`}
        getFormCallback={(_form) => setFiltersForm(_form)}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_SEPARATOR,
            label: "",
            stateKey: "",
          },
          {
            type: FILTER_MULTI_SELECT,
            stateKey: "locationIds",
            label: "Lokalizacje",
            formKey: "locations",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "locationIncrementId",
            label: "Numer porządkowy",
          },
          {
            type: FILTER_MULTI_SELECT,
            stateKey: "taskIds",
            label: "Zadania",
            formKey: "tasks",
            stateActiveKey: "type",
            stateActiveValue: "ACTIVE",
          },
          {
            type: FILTER_SELECT,
            stateKey: "taskStatus",
            formKey: "taskStatuses",
            label: "Status zadania",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "locationCustomerCode",
            label: "Kod klienta dla lokalizacji",
          },
          {
            type: FILTER_SELECT,
            stateKey: "locationCode",
            formKey: "locationCodes",
            label: "Zewnętrzny kod lokalizacji",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "locationName",
            label: "Lokalizacja nazwa/Id",
          },
          {
            type: FILTER_SELECT,
            stateKey: "visitHasComments",
            formKey: "visitHasComments",
            label: "Wizyty z komentarzem",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "nip",
            label: "Nip",
          },
          {
            type: FILTER_SELECT,
            stateKey: "networkId",
            formKey: "networks",
            label: "Sieć",
          },
          {
            type: FILTER_MULTI_SELECT,
            stateKey: "groupIds",
            formKey: "projectMerchRoles",
            label: "Grupa",
          },
          {
            type: FILTER_MULTI_SELECT,
            stateKey: "userRoleIds",
            formKey: "users",
            label: "Grupa -> Użytkownik",
            filter: ["groupIds", "roleId"],
            groupBy: "roleName",
            filterIgnoreIfEmpty: true,
          },
          {
            type: FILTER_SEPARATOR,
            label: "",
            stateKey: "",
          },
          {
            type: FILTER_MULTI_SELECT,
            stateKey: "statusIds",
            formKey: "visitStatus",
            label: "Statusy",
          },
          {
            type: FILTER_INPUT_DATE,
            stateKey: "startDate",
            label: "Data od",
          },
          {
            type: FILTER_INPUT_DATE,
            stateKey: "endDate",
            label: "Data do",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "street",
            label: "Ulica",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "city",
            label: "Miasto",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "community",
            label: "Gmina",
          },
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "district",
            label: "Powiat",
          },
          {
            type: FILTER_SELECT,
            formKey: "voivodeships",
            stateKey: "voivodeshipId",
            label: "Województwo",
          },
        ]}
      />

      {modalAddOpen && (
        <VisitsModalAdd
          locationsId={refLocationId}
          open={modalAddOpen}
          setOpen={setModalAddOpen}
          visitAddedCB={() => setTriggerLoad(Date.now())}
        />
      )}
      {modalNewLocationAddOpen && (
        <LocationProjectModalAdd
          setRefLocationId={setRefLocationId}
          open={modalNewLocationAddOpen}
          setOpen={setModalNewLocationAddOpen}
        />
      )}
    </>
  );
};

export default VisitsList;
