import React, { FC, FunctionComponent, useEffect, useState } from "react";
import styles from "../../../../../../admin/components/usersAdd/styles.module.scss";
import { FCC, IPhotoQuestionValue } from "../../../../../../../utils/models";
import Instances from "../../../../../../../utils/connections/instances";
import {
  Button,
  Form,
  IconButton,
  Input,
  Tooltip,
  Uploader,
  Whisper,
} from "rsuite";
import LocationIcon from "@rsuite/icons/Location";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import { FileType } from "rsuite/esm/Uploader/Uploader";
import { deepClone, handleToastRedux, rotateBase64Img } from "utils/helpers";

import Modal from "rsuite/Modal";
import ReloadIcon from "@rsuite/icons/Reload";
import { useDispatch } from "react-redux";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";
import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";
import ResizeIcon from "@rsuite/icons/Resize";
import { usePhotoContext, useStaticContext } from "../VisitHelpers";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";

interface IPhotoQuestion {
  refQuestionId: string;
  isDisabled: boolean;
}

export interface IPhotoQuestionValueExtended
  extends IPhotoQuestionValue,
    FileType {
  refQuestionId: string;
}

// unattached from visit form - because additional data attached after "upload" - which result in state issues
const PhotoQuestion: FunctionComponent<IPhotoQuestion> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [fullSize, setFullSize] = useState(false);
  const [fileToShow, setFileToShow] =
    useState<IPhotoQuestionValueExtended | null>();
  const [imageData, setImageData] = useState<string | null>(null);
  const [imageRotation, setImageRotation] = useState<number>(0);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const { visitPhotos, setVisitPhotos } = usePhotoContext();
  const { questionsFlatState } = useStaticContext();

  const visitQuestionPhotos = visitPhotos.filter(
    (vp) => vp.refQuestionId === props.refQuestionId
  );

  if (!visitQuestionPhotos) return <></>;

  const getLocationCoords = (
    callback: (position: GeolocationPosition | null) => void
  ) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => callback(position),
        () => callback(null)
      );
    } else {
      callback(null);
    }
  };

  const getImage = () => {
    setLoadingImage(true);
    const blobToBase64 = (blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
        reader.onerror = reject;

        reader.readAsDataURL(blob);
      });
    };
    if (fileToShow?.refQuestionAnswerId) {
      Instances.getQuestionImageBase64Data(
        fileToShow.refQuestionAnswerId,
        imageRotation
      ).then((blobFile: Blob) => {
        blobToBase64(blobFile).then((base64Data) => {
          setImageData(base64Data as string);
          setLoadingImage(false);
        });
      });
    }
  };
  const readAsDataURL = (
    file: FileType
  ): Promise<{ value: string; fileKey: string | number }> => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onerror = reject;
      fr.onload = ({ target }) => {
        resolve({
          value: target?.result?.toString() ?? "",
          fileKey: file.fileKey ?? "",
        });
      };
      if (file.blobFile) fr.readAsDataURL(file.blobFile);
    });
  };

  // added / removed
  const uploader = (
    _filelist: Array<FileType | IPhotoQuestionValueExtended>
  ) => {
    const actionRemove = _filelist.length < visitQuestionPhotos.length;
    if (actionRemove) {
      setVisitPhotos([
        // all photos NOT from this question
        ...visitPhotos.filter((vp) => vp.refQuestionId !== props.refQuestionId),
        // this questions photos that were not returned by the uploader
        ...(_filelist as IPhotoQuestionValueExtended[]),
      ]);
    } else {
      // new photo(s) added
      // get base64 from blobs
      const promises: ReturnType<typeof readAsDataURL>[] = [];
      const newPhotos = _filelist.filter(
        (f) => !("refQuestionAnswerId" in f)
      ) as FileType[];
      newPhotos.forEach((file) => {
        promises.push(readAsDataURL(file));
      });
      const newPhotosParsed = [] as IPhotoQuestionValueExtended[];

      // update / extend value for new photos
      Promise.all(promises).then((values) => {
        values.forEach((value) => {
          const fl = newPhotos.find((np) => np.fileKey === value.fileKey);
          if (fl) {
            newPhotosParsed.push({
              value: value.value,
              fileKey: fl.fileKey ?? "-",
              refQuestionAnswerId: "",
              refQuestionId: props.refQuestionId,
              comment: "",
              status: "finished",
              lat: null,
              lon: null,
            });
          }
        });

        // get and assign coords to new photos
        getLocationCoords((position: GeolocationPosition | null) => {
          if (position !== null) {
            const coords: GeolocationCoordinates | null =
              position?.coords ?? null;
            const lat = coords?.latitude.toString() ?? null;
            const lon = coords?.longitude.toString() ?? null;

            values.forEach((value) => {
              const fl_index = newPhotosParsed.findIndex(
                (f) => f.fileKey === value.fileKey
              );
              if (fl_index < 0) {
                console.log("index not found", value.fileKey, newPhotosParsed);
              } else {
                newPhotosParsed[fl_index].lat = lat;
                newPhotosParsed[fl_index].lon = lon;
              }
            });
          }

          setVisitPhotos([...visitPhotos, ...newPhotosParsed]);
        });
      });
    }
  };
  const handleClose = () => {
    setShowModal(false);
    setFileToShow(null);
    setImageData(null);
    setImageRotation(0);
  };
  const dispatch = useDispatch();
  const handleRotatedImageSave = () => {
    const cloned = deepClone(visitPhotos);
    const index = cloned.findIndex((c) => c.fileKey === fileToShow?.fileKey);
    cloned[index].value = imageData as string;
    setVisitPhotos(cloned);
    if (fileToShow?.refQuestionAnswerId) {
      handleToastRedux(
        Instances.putRotatedImage(
          fileToShow.refQuestionAnswerId,
          imageRotation
        ),
        dispatch,
        "Zapisanie pliku zdjęciowego nie powiodło się"
      ).then(() => {
        setImageRotation(0);
      });
    }
    handleClose();
  };

  useEffect(() => {
    getImage();
  }, [fileToShow, imageRotation]);

  const rotateImage = (isClockVise) => {
    const image: HTMLImageElement | null = document.getElementById(
      "active-image"
    ) as HTMLImageElement;

    setImageRotation((d) => (d + (isClockVise ? 90 : -90)) % 360);

    if (fileToShow?.refQuestionAnswerId === "") {
      const rotated = rotateBase64Img(image.src, isClockVise);
      setImageData(rotated);
    }
  };
  const RenderThumbnail: FCC<{
    key: number;
    file: FileType | IPhotoQuestionValueExtended;
    thumbnail?: React.ReactNode;
  }> = ({ file, thumbnail, key }) => {
    const _thumbnail =
      "value" in file && file.value ? (
        <img
          alt={file.name}
          src={file.value}
          style={{
            boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
            borderRadius: "5px",
            height: "100%",
          }}
        />
      ) : (
        thumbnail
      );
    return (
      <div
        key={`file_thumb${key}`}
        style={{
          position: "relative",
          overflow: "hidden",
          border: "1px solid silver",
          boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
          borderRadius: "5px",
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          height: "220px",
          width: "220px",
        }}>
        {file && "lat" in file && (!file.lat || !file.lon) && (
          <Whisper speaker={<Tooltip>Brak danych z GPS!</Tooltip>}>
            <LocationIcon
              color={"#ff0033"}
              style={{
                fontSize: "1.25em",
                position: "absolute",
                top: "4px",
                left: "4px",
                opacity: ".3",
              }}
            />
          </Whisper>
        )}
        {!props.isDisabled && (
          <Whisper speaker={<Tooltip>Usuń zdjęcie</Tooltip>}>
            <CloseOutlineIcon
              color={"#E09616"}
              onClick={() => {
                const cloned = deepClone(visitPhotos);
                const index = cloned.findIndex(
                  (c) => c.fileKey === file.fileKey
                );
                cloned.splice(index, 1);
                setVisitPhotos(cloned);
              }}
              style={{
                cursor: "pointer",
                fontSize: "1.25em",
                position: "absolute",
                top: "4px",
                right: "4px",
              }}
            />
          </Whisper>
        )}
        {file && "refQuestionAnswerId" in file ? (
          <>
            <div
              style={{ overflow: "hidden", height: "200px", cursor: "pointer" }}
              onClick={() => {
                setShowModal(true);
                setFileToShow(file);
                setImageData(file.value);
              }}
              className={styles.innerLink}>
              {_thumbnail}
            </div>
          </>
        ) : (
          _thumbnail
        )}
      </div>
    );
  };

  const RenderFileInfo: FC<{
    file: FileType | IPhotoQuestionValueExtended;
  }> = ({ file }) => {
    if ("refQuestionAnswerId" in file) {
      return (
        <Form.Group>
          <Form.Control
            name={`${props.refQuestionId}.description.${file.fileKey}`}
            size="xs"
            placeholder="opis zdjęcia..."
            accepter={Input}
            style={{ width: "100%" }}
            defaultValue={file.comment ?? undefined}
            onChange={(value: string | undefined) => {
              const cloned = deepClone(visitPhotos);
              cloned[
                cloned.findIndex((c) => c.fileKey === file.fileKey)
              ].comment = value ?? "";
              setVisitPhotos(cloned);
            }}
          />
        </Form.Group>
      );
    }
    return <></>;
  };
  const questionName = (refQuestionId: string | undefined) => {
    const question = Object.values(questionsFlatState).find(
      (q) => q.refQuestionId === refQuestionId
    );
    return question?.questionName ?? "";
  };
  const questionPhotosCount = (refQuestionId: string | undefined) =>
    visitPhotos.filter((vp) => vp.refQuestionId === refQuestionId)?.length ??
    "";

  return (
    <>
      {!imageData && (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Form.Control
              // name={props.refQuestionId}
              name={"__skip_me"}
              accepter={Uploader}
              multiple
              classPrefix={"photo-question-uploader"}
              size={"sm"}
              listType="picture-text"
              disabled={props.isDisabled}
              accept={"image/*"}
              onChange={uploader}
              autoUpload={false}
              fileListVisible={false}
              fileList={visitQuestionPhotos}
              removable={!props.isDisabled}
              //renderFileInfo={RenderFileInfo}
              //renderThumbnail={RenderThumbnail}
              action={""}>
              <IconButton
                style={{ display: props.isDisabled ? "none" : "inherit" }}
                appearance={"ghost"}
                disabled={props.isDisabled}
                icon={
                  <FileUploadIcon
                    color={`${props.isDisabled ? "#eeeee" : "#ffaf38"}`}
                  />
                }>
                Dodaj zdjęcia
              </IconButton>
            </Form.Control>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginTop: "10px",
                gap: "20px",
                maxHeight: "270px",
                overflow: "auto",
              }}>
              {visitQuestionPhotos &&
                visitQuestionPhotos.map((photo, key) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "220px",
                          justifyContent: "flex-start",
                        }}>
                        <RenderThumbnail key={key} file={photo} />
                        <RenderFileInfo key={`file_info_${key}`} file={photo} />
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </>
      )}
      <Modal
        open={showModal}
        onClose={handleClose}
        size={fullSize ? "full" : "lg"}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            width: "200px",
            position: "absolute",
            top: "10px",
            right: "50%",
            transform: "translateX(100px)",
          }}>
          <IconButton
            disabled={loadingImage && fileToShow?.refQuestionAnswerId != ""}
            onClick={rotateImage.bind(null, false)}
            style={{ transform: "rotateY(180deg)" }}
            appearance={"ghost"}
            icon={<ReloadIcon />}
          />
          <IconButton
            disabled={loadingImage && fileToShow?.refQuestionAnswerId != ""}
            onClick={rotateImage.bind(null, true)}
            appearance={"ghost"}
            icon={<ReloadIcon />}
          />
          <IconButton
            onClick={() => {
              setFullSize(!fullSize);
            }}
            appearance={fullSize ? "ghost" : "default"}
            icon={<ResizeIcon />}
          />
        </div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            left: "0px",
            top: "45%",
            zIndex: "500",
          }}>
          <AngleRightIcon
            style={{
              fontSize: "3em",
              cursor: "pointer",
              position: "absolute",
              right: "20px",
              top: "50%",
              color: "rgb(224, 150, 22)",
            }}
            onClick={() => {
              const index = visitPhotos.findIndex(
                (vp) => vp.fileKey === fileToShow?.fileKey
              );
              if (index < visitPhotos.length - 1) {
                setFileToShow(visitPhotos[index + 1]);
                setImageData(visitPhotos[index + 1].value);
              } else {
                setFileToShow(visitPhotos[0]);
                setImageData(visitPhotos[0].value);
              }
            }}
          />
          <AngleLeftIcon
            style={{
              fontSize: "3em",
              cursor: "pointer",
              position: "absolute",
              left: "20px",
              top: "50%",
              color: "rgb(224, 150, 22)",
            }}
            onClick={() => {
              const index = visitPhotos.findIndex(
                (vp) => vp.fileKey === fileToShow?.fileKey
              );
              if (index > 0) {
                setFileToShow(visitPhotos[index - 1]);
                setImageData(visitPhotos[index - 1].value);
              } else {
                setFileToShow(visitPhotos[visitPhotos.length - 1]);
                setImageData(visitPhotos[visitPhotos.length - 1].value);
              }
            }}
          />
        </div>
        <Modal.Header>
          <Modal.Title>
            Pytanie zdjęciowe - podgląd {fileToShow?.name}{" "}
            {fileToShow?.order &&
              `${fileToShow?.order} z ${questionPhotosCount(
                fileToShow.refQuestionId
              )}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}>
            <img
              alt=""
              id="active-image"
              style={{
                width: fullSize ? "95%" : "500px",
                height: fullSize ? "auto" : "100%",
              }}
              src={imageData ?? fileToShow?.value}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              position: "absolute",
              left: "10px",
              bottom: "10px",
              fontSize: "14px",
            }}>
            {questionName(fileToShow?.refQuestionId)}
          </div>
          {(fileToShow?.refQuestionAnswerId === "" || imageRotation !== 0) && (
            <Button onClick={handleRotatedImageSave} appearance={"ghost"}>
              Zapisz
            </Button>
          )}
          <Button onClick={handleClose} appearance={"subtle"}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PhotoQuestion;
